import { Leaderboards, CachedProductivityMetrics, Platform, IsNative, IsElectron, IsDev, UserEmail, UserInfo, DateRange, Tracking, DatesUploaded, Version, LiveConnections, Promises, Data } from '$lib/store.js';
import dayjs from "dayjs";
import { Capacitor } from '@capacitor/core';
import { serverURL, getUserInfo, objToQueryString, uploadTimeSeries, getTimeSeries, uploadNoPipeline, postUserInfo, get_sugar, getData, getDataUploaded, storeLocal, checkLocal, sleep, getJWT, getRefresh } from '$lib/utils.js'
import { get } from 'svelte/store';
import { setTodayData } from './dataProcessing';
import { screenTimeCategories } from './screenTimeCategories';
import { Plugins } from "@capacitor/core";
// importing js-queue
import * as Queue from 'js-queue';




export function padTime(num) {
    let numString = num.toString();
    return numString?.length < 2 ? '0' + numString : numString;
}

export function makeDecimalIntoTime(hourDecimal, stringType) {
    // console.log("makeDecimalIntoTime")
    if ((isNaN(hourDecimal) || !hourDecimal) && !stringType) return [undefined, "0"];
    if (isNaN(hourDecimal) || !hourDecimal) return "0m"
    let hour = Math.floor(hourDecimal) ? Math.floor(hourDecimal) : 0;
    let minute = hourDecimal % 1 ? Math.min(Math.floor((hourDecimal % 1) * 60), 59)
        : 0;
    let second = (hourDecimal * 60) % 1 ? Math.min(Math.ceil((hourDecimal * 60) % 1 * 60), 59) : 0;
    if (stringType === "time") return [hour, minute].map(t => padTime(t)).join(':')
    if (stringType === "durationNoZero") return [hour, minute].map((t, i) => t ? (t) + (i ? 'm' : 'h ') : "").join(' ').trim()
    if (stringType === "durationNoZeroFullText") return [hour, minute].map((t, i) => t ? (t) + (i ? ` minute${t > 1 ? 's' : ''}` : ` hour${t > 1 ? 's' : ''} `) : "").join(' ').trim()
    if (stringType === "durationNoZeroWithSeconds") return [hour, minute, second].map((t, i) => t && !(i == 2 && (hour || minute)) ? (t) + (i ? `${i === 1 ? 'm ' : 's'}` : 'h ') : "").join(' ').trim()
    if (stringType === "duration") return [hour, minute].map((t, i) => (t || 0) + (i ? 'm' : 'h ')).join(' ')
    else return [hour, minute];
}

export async function getSpotify(task, redirectPath) {
    getOauthedData('spotify', task, redirectPath)
}


export async function getGoogleCalendarData(options) {
    // /api/v1/data/calendar?start=2022-08-12T12:14:37.261373Z&end=2022-09-12T12:14:37.261373Z
    let { start, end } = options;
    start = dayjs(start).toISOString();
    end = dayjs(end).toISOString();
    return await get_sugar('/api/v1/data/calendar', { start, end })
}
export async function getOauthedData(source, task, redirectPath) {

    redirectPath = redirectPath || '/';
    let live = get(LiveConnections);;
    if (!(live && live[source] && typeof live[source] === "object")) return;
    let url = `/api/v1/oauth/${source}/${task}/`;
    return await get_sugar(url, redirectPath ? { path: redirectPath } : {})
        .catch((error) => {
            console.error(error);
            localStorage.removeItem(source + "-access");
        });
}


const { CapacitorHealthkit } = Plugins;
let sampleNames = [
    "stepCount",
    "restingHeartRate",
    "sleepAnalysis",
    "workoutType",
    "activeEnergyBurned",
    "flightsClimbed",
    "distanceWalkingRunning",
    "distanceCycling",
    "appleExerciseTime",
    "oxygenSaturation",
    "bodyTemperature",
    // "bloodPressureDiastolic",
    // "bloodPressureSystolic",
    "respiratoryRate",
    "heartRate",
    "walkingHeartRateAverage",
    "heartRateVariabilitySDNN",
    "basalEnergyBurned",
];

export async function oauthLogin(source, redirectPath) {
    redirectPath = redirectPath || '/connected?source=' + source;
    let url = `/api/v1/oauth/${source}/login/`;
    let res = await get_sugar(url, { path: redirectPath, client_redirect_path: redirectPath, redirect_path: redirectPath, rand: Math.random() });
    localStorage.removeItem(source + "Called");
    if (!res?.url) { console.error(res); return; }
    let w = window.open(res?.url, '_blank');
    await sleep(1000)
    // res.url && typeof w?.location?.href !== undefined ? (w.location.href = res.url) : console.log("url undefined");
    return res?.url
}
export async function twitterLogin(redirectPath) {
    return await oauthLogin('twitter')
}
export async function rescuetimeLogin() {
    return await oauthLogin('rescuetime')
}

export async function getTwitterProfile() {
    let profile = await get_sugar("/api/v1/oauth/twitter/me/").catch(r => console.error(r));
    localStorage.setItem("twitterCalled", "true");
    return profile;
}


export async function spotifyLogin(redirectOnLogin, spotifyRedirectPath) {
    // Request 1, flask-server/api/v1/oauth/spotify/login/
    let res = await getSpotify("login", spotifyRedirectPath);;
    let w = window.open('', '_blank');
    redirectOnLogin
        ? localStorage.setItem("spotify-redirect", redirectOnLogin)
        : "";
    // Redirect to the spotify Authorisation url in the response
    res?.url && typeof window !== "undefined" ? (w.location.href = res.url) : console.log("url undefined");
}

export async function appleLogin() {
    if (!get(IsNative)) return;
    let userInfo = get(UserInfo)
    if (!userInfo) {
        await getUserInfo("applelogin")
        userInfo = get(UserInfo)
    }
    if (!(userInfo.syncHKEnabled)) {
        userInfo.syncHKEnabled = true;
        UserInfo.set(userInfo);
        let live = get(LiveConnections);
        live.apple = { connected: true }
        LiveConnections.set(live)
        postUserInfo();
        if (!get(IsDev))
            window.analytics?.track?.('Connected Source', {
                source: 'apple',
                platform: get(Platform),
                environment: get(IsDev) ? 'dev' : 'production',
                email: get(UserEmail)
            });
    }
    await getHKAuth("forceNewRequest");
    sync('apple');
}

let isHKAuthed;
let HKAuthPromise;
export async function getHKAuth(forceNewRequest) {
    await getUserInfo("hkauth");
    if (!CapacitorHealthkit) return;
    if (get(UserInfo).syncHKEnabled) {
        if (isHKAuthed) return isHKAuthed;
        HKAuthPromise = (!forceNewRequest && HKAuthPromise) || CapacitorHealthkit.requestAuthorization(
            {
                all: [""], // ask for Read & Write permission
                read: [
                    "steps",
                    "distance",
                    "duration",
                    "calories",
                    "heartRate",
                    "oxygenSaturation",
                    "bodyTemperature",
                    // "bloodPressure",
                    "respiratoryRate",
                    "stairs",
                    "activity",
                    "workouts",
                ], // ask for Read Only permission
                write: [""], // ask for Write Only permission
            },
            () => isHKAuthed = { success: true },
            (err) => {
                ;
                let userInfo = get(UserInfo);
                userInfo.syncHKEnabled = false;
                UserInfo.set(userInfo);
                postUserInfo();
            }
        );
        return await HKAuthPromise
    }
}

export async function queryHK(daysInPast, hardResync) {
    daysInPast = daysInPast || 0;
    if (!get(IsNative)) {
        return;
    }
    if (!get(UserInfo).syncHKEnabled) return;
    await getHKAuth();
    let data = get(Data);
    let todaysDayjs = dayjs().subtract(4, "hour").subtract(daysInPast, "day").startOf('day');
    let todaysDate = todaysDayjs.format('YYYY-MM-DD');
    let cache = data[`["timeseries","time_series/apple","noCache"]`]
    if (!cache) {
        cache = checkLocal(`["timeseries","time_series/apple","noCache"]`);
        if (cache && (!data[`["timeseries","time_series/apple","noCache"]`] || Object.keys(cache).length !== Object.keys(data[`["timeseries","time_series/apple","noCache"]`]).length)) {
            data[`["timeseries","time_series/apple","noCache"]`] = { ...(cache || []), ...data[`["timeseries","time_series/apple","noCache"]`] };
            console.log("updating apple from queryhk")

            Data.set(data)
        }
    }
    let todaysData = data[`["timeseries","time_series/apple","noCache"]`]?.[todaysDate];
    let startDate;
    if (!hardResync) {
        if (todaysData && todaysData.lastUpdated && todaysData.lastUpdated > todaysDayjs.add(1, "day").valueOf()) {
            return todaysData;
        } else if (todaysData) {
            startDate = dayjs(todaysData.lastUpdated).subtract(30, "minute")
        }
    }
    console.log("querying HK", { daysInPast, startDate: (startDate || todaysDayjs).format() })
    let options = {
        sampleNames,
        startDate: (startDate || todaysDayjs).format(),
        endDate: dayjs()
            .subtract(4, "hour")
            .subtract(daysInPast - 1, "day").startOf("day")
            .format(),
        limit: 0,
    };
    let promises = get(Promises);
    let existingPromise = !hardResync && promises["queryHK" + daysInPast + (startDate || "")];
    if (existingPromise) {
        return existingPromise;
    }

    let error;
    let promise = existingPromise || CapacitorHealthkit.multipleQueryHKitSampleType(
        options
    ).catch(err => {
        console.error(e, "multipleQueryHKitSampleType", "getAppleHealthData", options)
        error = err;
    }
    );
    if (!existingPromise) {
        promises["queryHK" + daysInPast + (startDate || "")] = promise;
        promise.then(a => {
            delete promises["queryHK" + daysInPast + (startDate || "")];
            Promises.set(promises);
        });
        Promises.set(promises)
    }
    let promiseData = await promise;
    console.log({ promiseData });
    if (error || !promiseData) {
        console.error(promiseData)
        return data[`["timeseries","time_series/apple","noCache"]`]?.[todaysDate]
    }
    let appleData;
    if (startDate && todaysData) {
        appleData = Object.fromEntries(
            Object.entries(todaysData).filter(([key, object]) => typeof object === "object")
                .map(([key, object]) => {
                    console.log(key, object.resultData, promiseData[key].resultData)
                    let allData = [
                        ...(typeof object.resultData === "object" && object.resultData.length && object.resultData || []), ...(typeof promiseData[key].resultData === "object" && promiseData[key].resultData.length && promiseData[key].resultData || [])];
                    object.resultData = allData.filter((p, i) => allData?.findIndex(pe => pe.uuid === p.uuid) === i);
                    // if (!daysInPast) console.log("newData", key, { startDate, new: object.resultData?.length - allData?.length })
                    return [key, object]
                }
                )
        )
    } else appleData = promiseData;
    if (!appleData?.stepCount) return data[`["timeseries","time_series/apple","noCache"]`]?.[todaysDate];

    let day = {};
    day[todaysDate] = { ...(appleData || []), lastUpdated: dayjs().valueOf() };
    uploadTimeSeries(day[todaysDate], "apple", daysInPast)
    if (daysInPast < 33) {
        data = get(Data);
        data[`["timeseries","time_series/apple","noCache"]`] = { ...(data[`["timeseries","time_series/apple","noCache"]`] || {}), ...(day || []), lastUpdated: dayjs().valueOf() };
        console.log("updating apple from end of queryhk")

        Data.set(data);
    }
    return day[todaysDate];
}

export async function setUpBackgroundObservers() {
    // return;
    if (!get(IsNative)) {
        return;
    }
    if (!get(UserInfo).syncHKEnabled) return;
    await getHKAuth();
    // console.log("ready to set up background", {
    //     accessToken: getJWT(), refreshToken: getRefresh(), url: serverURL
    // })
    let setupController = await CapacitorHealthkit.setupHealthKitController({
        accessToken: getJWT(), refreshToken: getRefresh(), url: serverURL
    });
    let setupBackground = await CapacitorHealthkit.setUpBackgroundObservers({ sampleNames });
    // console.log({ setupBackground, setupController })
}
export let dataGetters = {
    apple: get(IsNative) ? queryHK : (daysInPast) => getTimeSeries('apple', { daysInPast }),
    activitywatch: get(IsElectron)
        ? queryScreenTime
        : (daysInPast) => getTimeSeries('activitywatch', { daysInPast }),
    screenTime: get(IsElectron)
        ? queryScreenTime
        : (daysInPast) => getTimeSeries('activitywatch', { daysInPast }),
    screentime: get(IsElectron)
        ? queryScreenTime
        : (daysInPast) => getTimeSeries('activitywatch', { daysInPast }),
    whoop: queryWhoop
};


export async function sync(source, options) {
    // we want to sync a day at a time, going back until someone's full history is in Magicflow
    // given that this will take a while, we'll want to do this in a non-blocking way, i.e. with timeouts between syncs
    // so the plan is: find the earliest date they have HealthKit data for, & go backwards until then, once per second or two?
    // then we want to update it with new data every time the app is opened, or when the user presses a refresh/sync button

    // if they have data uploaded and the most recent is not today, we upload every day since then, including the most recent day
    // at which point we will then continue any historical syncing still to do
    let validSources = ["apple",
        "activitywatch",
        "whoop"
    ]
    let validEnvironment = {
        apple: get(IsNative),
        activitywatch: get(IsElectron),
        whoop: true,
    }
    if (!(validSources.includes(source) && validEnvironment[source])) {
        ;
        return
    }
    window.dataUploadedPromise = window.dataUploadedPromise || getDataUploaded();
    await window.dataUploadedPromise;
    let { hardResync } = options || {};
    if (window["syncingWith" + source] && !hardResync) return;
    if (hardResync) window["hardResync" + source] = true;
    window["syncingWith" + source] = true;
    console.log("syncing ", source);
    let dataGetter = dataGetters[source];
    if (source === "apple") {
        if (!(get(IsNative) && get(UserInfo).syncHKEnabled)) return
        await getHKAuth();
    }
    if (source === "whoop") {
        if (!get(LiveConnections).whoop?.needsWhoopCredentials) {
            if (!(whoopUser && whoopUser.token)) {
                if (!window.whoopLoginPromise) window.whoopLoginPromise = whoopLogin().catch(error => console.log(error));
                await window.whoopLoginPromise;
                return
            }
        }
        else return;
    }
    let datesUploadedCache = checkLocal('datesUploaded');
    if (datesUploadedCache) DatesUploaded.set(datesUploadedCache);
    let allDates = get(DatesUploaded)

    if (!allDates || typeof allDates !== "object") return
    let dates = allDates && allDates[source] && allDates[source].sort((a, b) => new Date(a) - new Date(b));
    let mostRecentUploaded =
        dates?.length && dates[dates?.length - 1];
    let earliestUploaded = dates?.length && dates?.[0];

    await sleep(10000) // wait for other things to load
    async function syncLoop(dataGetter, source, options) {
        console.log('syncLoop', source, options)
        async function innerLoop(daysInPast) {
            console.log('innerLoop', daysInPast, window["hardResync" + source] && !hardResync, !get(UserEmail))
            if (window["hardResync" + source] && !hardResync || !get(UserEmail)) {
                return
            };
            let dataFromQuery = await dataGetter(daysInPast, hardResync);
            if (dataFromQuery) {
                console.log('dataGotteninner', daysInPast)
                dataFromQuery.lastUpdated = dayjs().valueOf();
                await uploadTimeSeries(dataFromQuery, source, daysInPast);
                await sleep(3500);
            }
            await sleep(5500);
        }
        if (options.dates) {
            for (let date of options.dates) {
                await innerLoop(dayjs().subtract(4, "hour").startOf('day').diff(dayjs(date), "day"))
            }
        }
        else {
            for (let i = options.start; i <= options.end; i++) {
                await innerLoop(i)
            }
        }
    }
    if (mostRecentUploaded && !hardResync) {
        // this block is for the topup sync if they already have data
        let daysSinceUpload = dayjs().diff(mostRecentUploaded, "d");
        // should reupload the day last uploaded, in case further updates came in since, and upload anything new since
        syncLoop(dataGetter, source, { start: 1, end: daysSinceUpload + 1 })
    }
    await sleep(5000)

    let daysInPastToStartHistoricalSyncFrom = earliestUploaded && !hardResync
        ? dayjs().startOf("day").diff(dayjs(earliestUploaded).startOf("day"), "d")
        : 0;

    let earliestDataForSourceGetter = {
        apple: {
            getter: CapacitorHealthkit && CapacitorHealthkit.multipleQueryHKitSampleType,
            params: [{
                sampleNames,
                startDate: dayjs(0).format(),
                endDate: dayjs().format(),
                limit: 1,
                ascending: true,
            }],
            extractEarliest: (earliestEver) => (Object.values(earliestEver)
                .map((earliest) =>
                    earliest?.resultData && earliest?.resultData?.length
                        ? earliest?.resultData?.[0].date || earliest?.resultData?.[0].startDate || earliest?.resultData?.[0].timestamp
                        : ""
                )
                .filter((i) => i)
                .sort((a, b) => new Date(a) - new Date(b)) || [])[0]
        },
        activitywatch: {
            getter: () => window.api?.call('earliestScreenTime').catch(e => console.error(e)),
            params: [],
            extractEarliest: date => date
        },
        whoop: {
            getter: () => get(LiveConnections).whoop,
            params: [],
            extractEarliest: whoop => whoop?.user?.createdAt
        }
    }
    let earliestEver = await earliestDataForSourceGetter[source].getter(...(earliestDataForSourceGetter[source] || []).params);
    let earliestDate = earliestDataForSourceGetter[source].extractEarliest(earliestEver);
    ;

    let totalHistoricalDaysToSync = dayjs().endOf("day").diff(dayjs(earliestDate).startOf("day"), "d") + 1;
    let allDatesSinceEarliest = Array(totalHistoricalDaysToSync && typeof totalHistoricalDaysToSync === "number" && totalHistoricalDaysToSync > 0 ? totalHistoricalDaysToSync : 0).fill().map((_, i) => dayjs().subtract(totalHistoricalDaysToSync - i, "day").format('YYYY-MM-DD'))




    await syncLoop(dataGetter, source, { start: daysInPastToStartHistoricalSyncFrom, end: totalHistoricalDaysToSync })
    if (!hardResync) {
        await getDataUploaded();
        allDates = get(DatesUploaded)

        if (!allDates || typeof allDates !== "object") return;
        dates = allDates && allDates[source] && allDates[source].sort((a, b) => new Date(a) - new Date(b));
        let datesNotUploaded = allDatesSinceEarliest.filter(date => !dates || !dates.includes(date));
        if (datesNotUploaded?.length) {
            ;
            syncLoop(dataGetter, source, { dates: datesNotUploaded });
        }
    }
}

let headers = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
}
class WhoopUser {
    constructor(email, password, start, end) {
        this.BASE_URL = "https://cors.magicflow.workers.dev/?https://api-7.whoop.com"
        this.AUTH_URL = "/oauth/token"
        this.email = email;
        this._password = password;
        this.token = undefined;
        this.refresh = undefined;
        this.user_id = undefined;
        this.header = undefined;
        this.CYCLES_URL = undefined;
        this.HEART_RATE_URL = undefined;
        this.SPORTS_URL = "/sports"
        this.start = start || "2022-01-23T00:00:00.000Z";
        this.end = end || "2022-01-29T00:00:00.000Z";
    }
    get default_params() {
        return { start: dayjs().subtract(4, "hour").startOf('day').format(), end: dayjs().endOf('day').format() }
    }

    async login() {
        /*
        Login to whoop API, storing User id && token
        :return: None will set class variables
        */


        let login = await fetch(
            this.BASE_URL + this.AUTH_URL,
            {
                method: "POST",
                body: JSON.stringify(this.refresh ? {
                    "refresh_token": this.refresh,
                    "grant_type": "refresh_token"
                } : {
                    "grant_type": "password",
                    "issueRefresh": "true",
                    "password": this._password,
                    "username": this.email,
                }),
                headers
            }
        )

        if (login.status != 200)
            console.error("Credentials rejected", login)

        let login_data = await login.json()
        this.token = login_data["access_token"];
        this.refresh = login_data["refresh_token"];
        this.user_id = login_data["user"]["id"];
        this.header = { "Authorization": `bearer ${this.token}` }
        this.CYCLES_URL = `users/${this.user_id}/cycles`
        this.HEART_RATE_URL = `/users/${this.user_id}/metrics/heart_rate`;
        this.user = login_data;
        this.expiryDate = dayjs().add(login_data.expires_in, "second");
        return login_data;
    }

    async get_cycles_json(params) {
        params = params || this.default_params;
        /*this
        Record base information
        :param params: start, end, other params
        :return: json with all info from cycles endpoint
        */
        let cycles_URL = `/users/${this.user_id}/cycles`
        let cycles_request = await fetch(this.BASE_URL + cycles_URL + '?' + objToQueryString(params), { headers: { ...(headers || []), ...(this.header || []) } })
        let json = await cycles_request.json();

        return json;
    }

    async get_heart_rate_json(params) {
        params = params || this.default_params;
        if (new Date(params.end) - new Date(params.start) >= 8 * 24 * 3600 * 1000) {
            console.error("maximum range 8 days");
            return
        }
        /*
        Get heart rate data on user
        :param params: params for heart rate data
        :return: dict of heart rate data
        */

        let hr_request = await fetch(this.BASE_URL + this.HEART_RATE_URL + '?' + objToQueryString(params, true), { headers: { ...(headers || []), ...(this.header || []) } }).catch(e => console.log(e));

        let data = await hr_request.json();

        return data;
    }
    async get_sports() {
        /*return: List of sports && relevant information.*/
        let sports_request = await fetch(this.BASE_URL + this.SPORTS_URL, { headers: { ...(headers || []), ...(this.header || []) } });
        let data = await sports_request.json();

        return data
    }
}

let whoopUser;
// ipcMain.handle("getWhoopHeartRate",
//     async (event, ...args) => {

//         if (!whoopUser) return "No Whoop user found, log in again."

//         let query = await whoopUser.get_heart_rate_json(args && args?.[0] && args?.[0].start ? args?.[0] : undefined);
//         return query;
//     })
// ipcMain.handle("getWhoopSports",
//     async (event, ...args) => {


//         let query = await whoopUser.get_sports();
//         return query;
//     })
export async function whoopLogin(email, password) {
    let login
    if (!email || !password) {
        let local = checkLocal("whoop");
        if (local)
            ({ email, password } = local);
        else return;
    }
    else storeLocal('whoop', { email, password });

    await getUserInfo("whooplogin");
    let userInfo = get(UserInfo)
    if (!whoopUser) {
        whoopUser = new WhoopUser(email, password);
    }
    if (email && password && !whoopUser.email || !whoopUser._password) {
        whoopUser.email = email;
        whoopUser._password = password;
    }
    typeof window !== "undefined" ? window.whoopUser = whoopUser : ""

    let live = get(LiveConnections);
    if (userInfo.whoop && (!whoopUser.expiryDate || !whoopUser.token || dayjs(userInfo.whoop.expiryDate) > whoopUser.expiryDate)) {
        whoopUser.expiryDate = dayjs(userInfo.whoop.expiryDate);
        whoopUser.token = userInfo.whoop["access_token"];
        whoopUser.refresh = userInfo.whoop["refresh_token"];
        whoopUser.user_id = userInfo.whoop["user"]["id"];
        whoopUser.header = { "Authorization": `bearer ${whoopUser.token}` }
        whoopUser.CYCLES_URL = `users/${whoopUser.user_id}/cycles`
        whoopUser.HEART_RATE_URL = `/users/${whoopUser.user_id}/metrics/heart_rate`;
        whoopUser.user = userInfo.whoop;
        live.whoop = userInfo.whoop
        LiveConnections.set(live)
    }
    if (!whoopUser.token && (!email || !password) && dayjs(whoopUser.expiryDate || dayjs()).diff(dayjs(), "second") < 30 * 60) {
        if (!live.whoop?.hasSeenModal)
            live.whoop = { needsWhoopCredentials: true };
        LiveConnections.set(live);;
        return;
    }

    ;
    if (!whoopUser.token || dayjs(whoopUser.expiryDate).diff(dayjs(), "second") < 12 * 60 * 60) {
        login = await whoopUser.login();
        if (login.user) {
            login.expiryDate = dayjs(whoopUser.expiryDate).format();
            userInfo = get(UserInfo)
            userInfo.syncWhoopEnabled = true;
            userInfo.whoop = login;
            if (!window.syncingWithwhoop) sync('whoop')
            UserInfo.set(userInfo);
            postUserInfo();
            storeLocal('whoopUser', login)
            let live = get(LiveConnections);
            live.whoop = login;
            LiveConnections.set(live);
        } else {
            ;
            throw "Whoop login failed"
        }
    }
    return login;
}
export async function queryWhoop(daysInPast) {
    // console.log("queryWhoop")
    daysInPast = daysInPast || 0;
    if (!(get(UserInfo).syncWhoopEnabled)) return;
    let data = get(Data);
    let todaysDate = dayjs().subtract(4, "hour").subtract(daysInPast, "day").hour(12).format('YYYY-MM-DD');
    let cache = checkLocal(`["timeseries","time_series/whoop","noCache"]`);
    if (cache && (!data[`["timeseries","time_series/whoop","noCache"]`] || Object.keys(cache).length !== Object.keys(data[`["timeseries","time_series/whoop","noCache"]`]).length)) {
        data[`["timeseries","time_series/whoop","noCache"]`] = { ...(cache || []), ...data[`["timeseries","time_series/whoop","noCache"]`] };
        console.log("updating whoop from qw")

        Data.set(data)
    }
    let dataToday = data[`["timeseries","time_series/whoop","noCache"]`]?.[todaysDate];
    if (daysInPast !== 0 && dataToday && dataToday?.[0] && dataToday?.[0].lastUpdatedAt.slice(0, 10) !== todaysDate) {

        return dataToday;
    }
    if (!(whoopUser && whoopUser.token)) {
        if (!window.whoopLoginPromise) window.whoopLoginPromise = whoopLogin().catch(error => console.log(error));
        await window.whoopLoginPromise;
        return
    }
    let dateRange = { start: dayjs().subtract(4, "hour").subtract(daysInPast, "day").hour(12).format(), end: dayjs().subtract(4, "hour").subtract(daysInPast, "day").hour(13).format() };
    if (!(whoopUser && whoopUser.token)) {
        ;
        return
    }

    let promises = get(Promises);
    let existingPromise = promises["whoop" + todaysDate];
    let error;
    if (existingPromise) {
        await existingPromise.catch(e => error = e);
        if (!error) {
            return existingPromise;
        }
    }
    let promise = whoopUser.get_cycles_json(dateRange).catch(error => console.log(error));
    promises = get(Promises);
    promises["whoop" + todaysDate] = promise;
    Promises.set(promises);
    let whoopToday = await promise;
    if (typeof whoopToday === "string") {
        return
    }
    if (!(whoopToday && whoopToday?.[0])) {
        ;
        return;
    }

    let day = {};
    day[todaysDate] = { ...(whoopToday || []), lastUpdated: dayjs().valueOf() };

    data = get(Data);
    data[`["timeseries","time_series/whoop","noCache"]`] = { ...(data[`["timeseries","time_series/whoop","noCache"]`] || {}), ...(day || []) };
    console.log("updating whoop from qwend")

    Data.set(data);
    storeLocal(`["timeseries","time_series/whoop","noCache"]`, data[`["timeseries","time_series/whoop","noCache"]`]);
    return whoopToday;
}
export function query(source) { return dataGetters[source] || console.error('no getter', source) }
// console.log("query")
typeof window !== "undefined" ? window.queryWhoop = queryWhoop : ""
typeof window !== "undefined" ? window.query = (source) => dataGetters[source] : ""
typeof window !== "undefined" ? window.dayjs = dayjs : ""
typeof window !== "undefined" ? window.getLive = () => get(LiveConnections) : ""
let lastQueried = {};

let previousSession;
let cloud = {}
let lastUpdatedCloud;

export async function updateScreenTime(cloudToday, session) {
    if (!(get(IsElectron) && get(UserInfo).syncActivityWatchEnabled)) return;
    if (!lastUpdatedCloud || Date.now() - lastUpdatedCloud > 5 * 60 * 1000) {
        cloudToday = await getTimeSeries('activitywatch', { daysInPast: 0, noStoring: true });
        lastUpdatedCloud = Date.now();
    }
    console.log("updateScreenTime")
    let data = get(Data);
    let dateForDay = dayjs().subtract(4, "hour").startOf('day').hour(4).startOf('hour').format('YYYY-MM-DD');
    // if (session && session.events?.length) {
    //     data[dateForDay].productivityLastHour = session;
    // }

    if (!data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay] || (
        !data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]?.productivityMetrics?.totalSessionTime ||
        data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]?.productivityMetrics?.totalSessionTime < 10
    )) {
        console.error("no data for day " + dateForDay, data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]);
        await query('screentime')(0, true, true);
        // return;
    };
    // to fix performance
    // get latest session metrics and events

    if (!session) {
        try {
            let userInfo = get(UserInfo);
            console.log("getting getCurrentSession")
            session = await window.api?.call('getCurrentSession', userInfo.personalCategories);
        } catch (e) {
            console.error(e)
        }
    }

    if (!session) {
        console.error("no session")
        data = get(Data);
        if (data[dateForDay]?.productivityLastHour)
            data[dateForDay].productivityLastHour = {}
        return;
    }
    if (!session?.events?.length) {
        console.error("no session events")
        return;
    }

    // aggregate and sort latest session, merge with existing aggregated and sorted cache
    // session.aggregated = aggregateAndSort(session.events);
    // cache all previous sessions as existing productivity metrics, merge with current session metrics when latest is called
    let cachedProductivityMetrics = get(CachedProductivityMetrics);
    if (!cachedProductivityMetrics?.totalSessionTime || dayjs(cachedProductivityMetrics?.start).format('YYYY-MM-DD') !== dateForDay) {
        let prevSessions = data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]?.window?.sessions?.slice(0, -1)
        if (prevSessions?.length > 1) {
            cachedProductivityMetrics = prevSessions?.reduce(mergeProductivityMetrics)
        } else if (prevSessions?.length) cachedProductivityMetrics = prevSessions?.[0]
        else cachedProductivityMetrics = {}
        CachedProductivityMetrics.set(cachedProductivityMetrics)
    }
    if (previousSession && previousSession?.id !== session.id && dayjs(previousSession?.timestamp).format('YYYY-MM-DD') === dateForDay) {
        cachedProductivityMetrics = mergeProductivityMetrics(previousSession, cachedProductivityMetrics);
        CachedProductivityMetrics.set(cachedProductivityMetrics);
    }
    previousSession = session;

    CachedProductivityMetrics.set(cachedProductivityMetrics);
    let merged = mergeProductivityMetrics(session, cachedProductivityMetrics);
    data = get(Data)
    if (session?.events?.length && !data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]) {

        if (!data?.[`["timeseries","time_series/activitywatch","noCache"]`]) return;
        data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay] = {
            window: {
                events: session.events,
                sessions: [session],
                duration: session.duration,
            },
            productivityMetrics: merged,
            productivityLastHour: session,
            lastUpdated: dayjs().valueOf(),
            productivityByHour: []
        }
        console.log("updating activitywatch from updatescreentime1", session)

        Data.set(data);
        return true;
    }
    if (!data?.[`["timeseries","time_series/activitywatch","noCache"]`]) return;
    let sessions = data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]?.window.sessions.filter(e => dayjs(e?.timestamp).format('YYYY-MM-DD') === dateForDay) || []
    if (!data?.[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]) data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay] = {};
    data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay].productivityMetrics = merged;
    data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay].window.events = mergeEvents(session.events, data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]?.window?.events?.filter(e => dayjs(e?.timestamp).format('YYYY-MM-DD') === dateForDay));
    data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay].window.sessions = mergeEvents([session], sessions);
    data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay].window.duration = sessions.reduce((a, b) => a + b.duration, 0);
    data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay].productivityLastHour = session;
    data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay].productivityByHour = data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay].productivityByHour?.map((pm, i, a) => {
        let sessionsInHour = sessions.filter(s => dayjs(typeof s.timestamp == "string" ? s.timestamp : s.timestamp) >= dayjs(typeof pm.start == "string" ? pm.start : pm.start) && dayjs(typeof s.timestamp == "string" ? s.timestamp : s.timestamp) < dayjs(typeof pm.end == "string" ? pm.end : pm.end));
        if (!sessionsInHour.length) return pm;
        else return sessionsInHour.reduce(mergeProductivityMetrics)
    }).sort((a, b) => dayjs(typeof a.start == "string" ? a.start : a.start) - dayjs(typeof b.start == "string" ? b.start : b.start));
    if (cloudToday)
        data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay] = mergeLocalWithCloud(data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay], cloudToday, 0)
    data[`["timeseries","time_series/activitywatch","noCache"]`][dateForDay].lastUpdated = Date.now()
    console.log("updating activitywatch from updatescreentime2", session)

    Data.set(data);
    // if (get(DateRange).daysInPast === 0) setTodayData(0, "updateScreentime")
    return true;
    // recreate productivitybyhour just from sessions

    // calculate averageproductivitybyhour once, don’t update with todays data

    // calculate and cache datesScreenTime stuff at a higher level too, for better readability
}
typeof window !== "undefined" ? window.updateScreenTime = updateScreenTime : ""



let wsSuccess;
let queue;
let inProgress = {};
function wsend(obj) {
    if (window.ws?.readyState === WebSocket.OPEN) {
        try {

            window.ws?.send(JSON.stringify(obj));
        } catch (e) {
            console.error(e);
            setTimeout(() => {
                wsend(obj);
            }, 1000);

        }
    } else {
        setTimeout(() => {
            wsend(obj);
        }, 100);
    }
}
let awaitSendData = {};
function awaitSend(data) {
    let id = data.id || Math.random().toString(36); //.substring(7);
    data.id = id;
    data.timestamp = Date.now();
    return Promise.race([
        new Promise((resolve, reject) => {
            setTimeout(() => {
                reject('awaitSend timeout: ' + id, Date.now() - data.timestamp);
            }, 10000);
        }),
        new Promise((resolve, reject) => {
            wsend(data);
            awaitSendData[id] = { resolve, reject };
            // now we wait for the response to come back from the server and resolve the promise
        })
    ]);
}
export async function sendViaSocket(args, callback) {
    try {

        await awaitSend(args).catch(e => console.error(e))
    } catch {
        await connectSocket(data => {
            if (data.result && data.name === 'getScreenTimeToday')
                ingestScreenTime(data.result, data.daysInPast, data[4]);
            else {
                console.log('ws', data)
            }
        }, 'web');
        try {
            await window.ws?.send(JSON.stringify(args));
        } catch {
            callback?.();
        }
    }
}
export function connectSocket(callback, socketName, port) {
    // function heartbeat() {
    //     clearTimeout(this.pingTimeout);

    //     // Use `WebSocket#terminate()`, which immediately destroys the connection,
    //     // instead of `WebSocket#close()`, which waits for the close timer.
    //     // Delay should be equal to the interval at which your server
    //     // sends out pings plus a conservative assumption of the latency.
    //     this.pingTimeout = setTimeout(() => {
    //       this.terminate();
    //     }, 30000 + 1000);
    //   }

    //   const client = new WebSocket('wss://websocket-echo.com/');

    //   client.on('error', console.error);
    //   client.on('open', heartbeat);
    //   client.on('ping', heartbeat);
    //   client.on('close', function clear() {
    //     clearTimeout(this.pingTimeout);
    //   });
    return new Promise((resolve, reject) => {
        console.log(window.ws && window.ws?.readyState < 2 ? "not trying" : "trying to open socket", window.ws?.readyState)
        window.ws = window.ws && window.ws?.readyState < 2 ? window.ws : new WebSocket(`ws://localhost:${port || (get(Version)?.app == 'omnipilot' ? 1167 : 8167)}/` + `?name=${socketName}`);
        console.log("attempting socket connection..", window.ws.readyState)
        let opened;
        window.ws.onopen = function () {
            opened = true
            resolve(opened);
        }
        setTimeout(() => opened ? "" : resolve(false), 20000)

        window.ws.onclose = () => { console.log("closed, reconnecting in 1 second"); setTimeout(() => wsSuccess = connectSocket(callback, socketName, port), 1000); };
        window.ws.onerror = (e) => { console.error("error, reconnecting in 1 second ", e); setTimeout(() => wsSuccess = connectSocket(callback, socketName, port), 1000); };
        window.ws.onmessage = function (event) {
            try {

                const data = JSON.parse(event?.data);
                if (data.debug) console.log(data);
                if (data.id && awaitSendData[data.id]) {
                    if ((data.name || data.req?.name) != 'swiftStatus')
                        console.log(
                            'resolving promise for id',
                            data.id,
                            data,
                            'after',
                            Date.now() - awaitSendData[data.id].timestamp,
                            'ms'
                        );

                    awaitSendData[data.id].resolve(data.data || data);
                    delete awaitSendData[data.id];
                }
                callback(data)
            } catch (e) {
                // console.error(e, event?.data, event)
            }
            // Process data
        };
    }
    );
}
let restarting, lastRestarted;
async function restartWebsocket() {
    if (restarting || Date.now() - lastRestarted < 60000) {
        console.log("too soon to restart websocket");
        return
    }
    restarting = true;
    lastRestarted = Date.now();
    try {
        await window.api?.call('restartWebsocket');
    } catch (error) {
        console.error(error, "failed to restart websocket")
    }
    restarting = false;
}
async function ingestScreenTime(local, daysInPast, requestedByUser) {
    if (!local?.window?.events) return;
    if (local) {
        local.start = typeof local.start == "string" ? local.start : local.start
        local.end = typeof local.end == "string" ? local.end : local.end
    }
    let userInfo = get(UserInfo)
    daysInPast = daysInPast || local.daysInPast;

    let data = get(Data);
    let dateForDay = dayjs().subtract(4, "hour").subtract(daysInPast || 0, "day").startOf('day').hour(4).startOf('hour').format('YYYY-MM-DD')
    // console.log({ local, dateForDay })
    inProgress[daysInPast] = false;
    console.log("return", daysInPast, { inProgress, queue })
    // if (!local?.productivityMetrics?.totalSessionTime) {
    //     console.log("no local data returned for ", daysInPast, { local, dateForDay }, data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]);
    //     return data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay];
    // }
    // if (!daysInPast)
    //     console.log(screenTimeToday.productivityMetrics?.totalSessionTime, screenTimeToday.productivityMetrics?.contexts?.length, screenTimeToday, screenTimeToday.productivityMetrics, "stt");
    if (!daysInPast && local?.window?.sessions?.length >= 2) CachedProductivityMetrics.set(local?.window?.sessions?.filter(a => a?.id).slice(0, -1)?.reduce(mergeProductivityMetrics));
    if (!data?.[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]) {
        let day = {}
        day[dateForDay] = { ...(local || []), lastUpdated: dayjs().valueOf() };
        data[`["timeseries","time_series/activitywatch","noCache"]`] = { ...(data[`["timeseries","time_series/activitywatch","noCache"]`] || {}), ...(day || []), lastUpdated: dayjs().valueOf() };
        console.log("updating activitywatch from ingestescreentime1")

        Data.set(data)
    }

    console.log("awaiting cloud for ", daysInPast, cloud)
    await Promise.any([cloud[daysInPast]?.then(a => console.log("got cloud for ", daysInPast)), sleep(12000).then(() => { console.log("cloud timeout for ", daysInPast); cloud[daysInPast] = daysInPast && cloud[daysInPast] || getTimeSeries('activitywatch', { daysInPast, noStoring: true }); })]);
    if (!daysInPast) { console.log("screenTimeToday1", local); }
    let screenTimeToday = mergeLocalWithCloud(local, cloud?.[daysInPast], daysInPast);
    if (!daysInPast) { console.log("screenTimeToday2", screenTimeToday); }
    let day = {}
    day[dateForDay] = { ...(screenTimeToday || []), lastUpdated: dayjs().valueOf() };

    if (daysInPast < 33 || requestedByUser) {
        console.log("setting data for daysInPast: ", daysInPast)
        data = get(Data);
        data[`["timeseries","time_series/activitywatch","noCache"]`] = { ...(data[`["timeseries","time_series/activitywatch","noCache"]`] || {}), ...(day || []), lastUpdated: dayjs().valueOf() };
        console.log("updating activitywatch from screentime2")
        Data.set(data);
        // setTimeout(() => , 9999)

        let triggerTodaysData;
        if (!data[dateForDay]?.productivityMetrics?.totalSessionTime && get(DateRange)?.daysInPast === daysInPast) setTodayData(daysInPast, "queryscr")
        if (triggerTodaysData) DateRange.set(get(DateRange))
        if (userInfo.groups && userInfo.username && screenTimeToday?.productivityMetrics) {
            let leaderboardMetrics = extractLeaderboardMetrics(day)
            // console.log(leaderboardMetrics)
            leaderboardTask('updateLeaderboard', userInfo.groups, userInfo.username, leaderboardMetrics);
        }
    }
    if (userInfo.screenTimeLocalOnly && screenTimeToday?.window?.events) {
        // let screenTimeStrippedOfTitlesAndURLs = {
        //     ...(day[dateForDay] || []),
        //     window: {
        //         ...(screenTimeToday.window || []),
        //         "events": [...screenTimeToday.window.events || []].filter(a => a?.id).map(e => ({
        //             ...(e || []),
        //             title: undefined, url: undefined
        //         })),
        //     },
        //     productivityMetrics: {
        //         ...(screenTimeToday.productivityMetrics || []), previous: undefined,
        //         contexts: screenTimeToday.productivityMetrics?.contexts?.filter(a => a?.id).map(e => ({ ...(e || []), title: undefined, url: undefined }))
        //     },
        //     productivityByHour: screenTimeToday?.productivityByHour?.map(p => ({ ...(p || []), previous: undefined, contexts: p?.contexts?.map(e => ({ ...(e || []), title: undefined, url: undefined })) })),
        //     productivityLastHour: {
        //         ...(screenTimeToday.productivityLastHour || []), previous: undefined,
        //         contexts: screenTimeToday.productivityLastHour?.contexts?.filter(a => a?.id).map(e => ({ ...(e || []), title: undefined, url: undefined }))
        //     },
        // };
        // // console.log({ screenTimeStrippedOfTitlesAndURLs })
        // uploadTimeSeries(screenTimeStrippedOfTitlesAndURLs, 'activitywatch', daysInPast);
    } else uploadTimeSeries(day[dateForDay], 'activitywatch', daysInPast);

    return day[dateForDay];
}
export async function queryScreenTime(daysInPast, refresh, requestedByUser) {
    if (!queue) {
        queue = new (Queue.default || Queue);
        queue.autoRun = true;
    }
    daysInPast = daysInPast || 0;
    if (lastQueried[daysInPast] && Date.now() - lastQueried[daysInPast]?.valueOf() < 30000) {
        console.log("it's been less than 30s for: ", daysInPast, lastQueried[daysInPast]?.format(), dayjs().diff(lastQueried[daysInPast], "second", true))
        // updateScreenTime()
        return
    };
    lastQueried[daysInPast] = dayjs();
    if (daysInPast < 0) return;
    let data = get(Data)
    let dateForDay = dayjs().subtract(4, "hour").subtract(daysInPast, "day").startOf('day').hour(4).startOf('hour').format('YYYY-MM-DD')
    console.log("queryScreenTime", { daysInPast, dateForDay, lastQueried })

    let userInfo = get(UserInfo)
    if (!(get(IsElectron) && userInfo.syncActivityWatchEnabled)) { console.log("!(get(IsElectron) && userInfo.syncActivityWatchEnabled)"); return; }

    let watcherStatus = get(Tracking).watcherStatus
    console.log("watcher", watcherStatus, daysInPast)
    if (watcherStatus !== "running" && get(UserInfo).syncActivityWatchEnabled) {
        let status = await window.api?.call("watcher");
        let t = get(Tracking);
        t.watcherStatus = status;
        Tracking.set(t);
        await sleep(1000)
        console.log("awaiting watcher", get(Tracking).watcherStatus, daysInPast);
        if (get(Tracking).watcherStatus !== "running") {
            console.log("watcher not running", get(Tracking).watcherStatus, daysInPast);
            return data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay];
        }
    };
    try {
        cloud[daysInPast] = daysInPast && cloud[daysInPast] || getTimeSeries('activitywatch', { daysInPast, noStoring: true });
        // console.log(cloud)
        let local;
        let dataForDay = data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]
        if (dataForDay) {
            dataForDay.start = typeof dataForDay.start == "string" ? dataForDay.start : dataForDay.start
            dataForDay.start = typeof dataForDay.end == "string" ? dataForDay.end : dataForDay.end
        }

        if (
            daysInPast && // always query for today
            !refresh && //always query for refresh
            (dayjs(dataForDay?.lastUpdated) > dayjs(dataForDay?.end)
            ) &&  // query if it's been more than 90 seconds since last query and the last query was before the end of the day
            dataForDay?.productivityMetrics?.totalSessionTime &&  // always query if we don't have session time for the day
            dayjs(dataForDay?.start).format().slice(0, 10) === dateForDay // always query if the date is not the same as the date for the day
        ) {
            console.log('not querying for ', daysInPast, data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay]);
            local = data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay];
            if (local) {
                local.start = typeof local.start == "string" ? local.start : local.start
                local.end = typeof local.end == "string" ? local.end : local.end
            }
        }
        else {
            if (daysInPast)
                queue.add(queuedQuery)
            else if (!inProgress[daysInPast]) queuedQuery();

            console.log(queue.contents)
            async function queuedQuery() {
                if (inProgress[daysInPast]) { queue.add(queuedQuery); return };
                lastQueried[daysInPast] = dayjs();
                // function getScreenTime()

                if (!await wsSuccess) {

                    wsSuccess = await wsSuccess;
                    wsSuccess = wsSuccess || connectSocket(data => {
                        if (data.result && data.name === 'getScreenTimeToday')
                            ingestScreenTime(data.result, data.daysInPast, data[4]);
                        else {
                            console.log('ws', data)
                        }
                    }, 'web');
                    console.log({ wsSuccess })
                }
                userInfo = get(UserInfo)
                let args = [daysInPast, refresh, get(UserEmail), {
                    personalCategories: userInfo.personalCategories || [],
                    customContexts: userInfo?.customContexts || [
                        {
                            "main": [
                                "Work",
                                "Focus Work",
                                "Writing & Notes"
                            ],
                            "categories": [
                                "Writing & Notes",
                                "Reference & Learning"
                            ],
                            "editingCategories": false
                        },
                        {
                            "main": [
                                "Work",
                                "Focus Work",
                                "Marketing"
                            ],
                            "categories": [
                                "Marketing",
                                "Social Media"
                            ],
                            "editingCategories": false
                        },
                        {
                            "main": [
                                "Work",
                                "Communication",
                                "Voice Chat"
                            ],
                            "categories": [
                                "Communication",
                                "Social Media"
                            ],
                            "editingCategories": false
                        }
                    ],
                    workModifications: userInfo.workModifications || { "Reading": true },
                    focusModifications: userInfo.focusModifications || { "Reading": false },
                }, requestedByUser];
                //mike
                // check if a request is already happening, if so, queue it.
                // global object with whether a message has got return message
                // in queue item check if it has, if not, wait for it to have

                // if it has, then finish that item in queue and do next

                //oscar
                // set request id, queue with staged requests
                // global data structure to check what has returned

                if (await wsSuccess) {

                    await sendViaSocket({ name: "getScreenTimeToday", args: args, debug: get(IsDev) }, queue?.clear);
                    let sent = Date.now();
                    while (inProgress[daysInPast] && Date.now() - sent < 75000) {
                        if ((Date.now() - sent) % 15000 < (get(IsDev) ? 15000 : 1500)) {
                            console.log(
                                "sleeping",
                                daysInPast,
                                "for: " + Math.round((Date.now() - sent) / 1000) + 's',
                                { inProgress, queue });
                        }
                        await sleep(2000)
                    }
                    if (inProgress[daysInPast] && Date.now() - sent > 75000) {
                        try {
                            if (get(Version)?.version < "0.2.0" && (new URL(window.ws?.url))?.port != 1167)
                                await restartWebsocket();
                        } catch {
                            console.error("failed to restart websocket")
                        }
                        if (queue?.contents?.length > 35)
                            queue.clear();
                    }
                }
                else {
                    //restart socket
                    if (get(Version)?.version < "0.2.0" && (new URL(window.ws?.url))?.port != 1167)
                        await restartWebsocket();
                    local = await window.api?.call('getScreenTimeToday', ...args);
                    if (local)
                        await ingestScreenTime(local, local.daysInPast, requestedByUser);
                }
                this?.next()
            }
        };

    } catch (e) {
        console.error(e);
        return data[`["timeseries","time_series/activitywatch","noCache"]`]?.[dateForDay];
    }
}
function mergeEvents(events, newEvents) {
    // a unique ID would be the timestamp + the title + the ID + the sessionID
    if (typeof events !== "object" || typeof newEvents !== "object") {
        console.log(events, newEvents)
    }
    return [...events, ...newEvents].map(e => {
        if (e)
            e.uid = "" + dayjs(typeof e.timestamp == "string" ? e.timestamp : e.timestamp).format() + e.id + (e.sessionId || '')
        return e
    }).filter((e, i, arr) => e?.id && arr.findIndex(e2 => e2.uid === e.uid) === i).sort((a, b) => dayjs(typeof a.timestamp == "string" ? a.timestamp : a.timestamp) - dayjs(typeof b.timestamp == "string" ? b.timestamp : b.timestamp))
}
function mergeProductivityMetrics(pm1, pm2) {
    let metricsToAccumulate = ["deepWork", "totalSessionTime", "traction", "duration", "activeDuration", "eventDuration", "switches", "appSwitches", "contextSwitches"];
    let metricsToIgnore = ["previous", "options", "id", "periodInMinutes", "timestamp", "endDate", "start", "status", "end"];
    function averageByTimeSpent(metric, pm1, pm2) {
        if (!pm1?.[metric] || !pm2?.[metric]) return pm1?.[metric] || pm2?.[metric];
        let totalDuration = (pm1?.totalSessionTime || 0) + (pm2?.totalSessionTime || 0)
        return ((pm1?.[metric] || 0) * (pm1?.totalSessionTime || 0) + (pm2?.[metric] || 0) * (pm2?.totalSessionTime || 0)) / totalDuration
    }
    let mergedPM = {}
    Object.keys(pm1).forEach(metric => {
        if (metricsToIgnore.includes(metric)) {
            mergedPM[metric] = pm1[metric]
            return;
        } else if (metricsToAccumulate.includes(metric)) {
            if (metric === "switches") {
                if (!pm1[metric] || !pm2[metric]) {
                    mergedPM[metric] = pm1[metric] || pm2[metric]
                    return;
                }
                mergedPM[metric] = {};
                Object.keys({ ...(pm1.switches || {}), ...(pm2.switches || {}) }).forEach(key => {
                    mergedPM[metric][key] = ((pm1?.[metric] || [])[key] || 0) + ((pm2?.[metric] || [])[key] || 0)
                })
            } else
                mergedPM[metric] = (pm1?.[metric] || 0) + (pm2?.[metric] || 0)
        } else if (metric === "contexts") {
            mergedPM[metric] = mergeEvents((pm1?.[metric] || []), (pm2?.[metric] || []));
        } else {
            mergedPM[metric] = averageByTimeSpent(metric, pm1, pm2)
        }
    })
    if (mergedPM.contextSwitches < pm1.contextSwitches || mergedPM.contextSwitches < pm2.contextSwitches) {
        console.log("contextSwitches", mergedPM.contextSwitches, pm1.contextSwitches, pm2.contextSwitches)
    }
    return mergedPM;
}
function mergeLocalWithCloud(local, cloud, daysInPast) {
    if (!local || !cloud) return local;
    if (dayjs(cloud.start).format("YYYY-MM-DD") !== dayjs(local.start).format("YYYY-MM-DD")) return local;
    let newSessionsInCloud = cloud?.[daysInPast]?.window?.sessions?.filter(s => dayjs(dateForDay).add(4, 'hour') <= dayjs(typeof s.timestamp == "string" ? s.timestamp : s.timestamp)).some(s => !local?.window?.sessions?.find(l => (dayjs(typeof l.timestamp == "string" ? l.timestamp : l.timestamp).format() + l.id) === (dayjs(typeof s.timestamp == "string" ? s.timestamp : s.timestamp).format() + s.id)));
    console.log("newSessionsInCloud? ", newSessionsInCloud)
    if (!newSessionsInCloud?.length) return local;

    // so we need to sync events across devices
    // productivityMetrics too
    // for events it's easy, just merge them with the existing ones via filtering on unique IDs

    // for productivityMetrics, we need to average metrics over the duration of the session
    // and accumulate any durations and aggregate scores
    // we also need to merge the productivityByHour and productivityLastHour

    // get list of unique sessions
    let sessions = mergeEvents(local?.window?.sessions, cloud?.window?.sessions).filter(s => dayjs(typeof s.timestamp == "string" ? s.timestamp : s.timestamp).format('YYYY-MM-DD') === dayjs(local.start).format('YYYY-MM-DD'));
    if (sessions.length < 2) return local;
    let merged = {
        ...local,
        productivityMetrics: sessions.reduce(mergeProductivityMetrics),
        productivityByHour: local.productivityByHour.map((pm, i, a) => {
            if (pm) {
                pm.start = typeof pm.start == "string" ? pm.start : pm.start
                pm.end = typeof pm.end == "string" ? pm.end : pm.end
            }
            let sessionsInHour = sessions.filter(s => dayjs(typeof s.timestamp == "string" ? s.timestamp : s.timestamp) >= dayjs(pm.start) && dayjs(typeof s.timestamp == "string" ? s.timestamp : s.timestamp) < dayjs(pm.end));
            if (!sessionsInHour.length) return pm;
            else return sessionsInHour.reduce(mergeProductivityMetrics)
        }).sort((a, b) => dayjs(typeof a.start == "string" ? a.start : a.start) - dayjs(typeof b.start == "string" ? b.start : b.start)),
        window: {
            activeDuration: sessions.reduce((a, b) => a + b.duration, 0),
            duration: sessions.reduce((a, b) => a + b.eventDuration, 0),
            events: mergeEvents(local?.window?.events, cloud?.window?.events.map(e => {
                if (e.categories.includes('Reading')) { e.work = true; e.focus = true; };
                return e
            })),
            sessions: sessions,
            active_events: mergeEvents(local?.window?.active_events, cloud?.window?.active_events),
        },
        lastUpdatedCloud: cloud.lastUpdated
    }
    console.log("merged", merged)
    return merged;
}


export function extractLeaderboardMetrics(screenTimeStore) {
    // console.log("extractLeaderboardMetrics")
    if (!screenTimeStore || typeof screenTimeStore !== "object") return;
    return Object.fromEntries(Object.entries(screenTimeStore).filter(([date, screenTimeToday]) => typeof screenTimeToday === "object").map(([date, screenTimeToday]) => {
        if (screenTimeToday?.productivityMetrics) {
            let metrics = {
                contextSwitchRate: screenTimeToday?.productivityMetrics.contextSwitchRate,
                traction: screenTimeToday?.productivityMetrics.traction,
                productivityScore: screenTimeToday?.productivityMetrics.productivityScore,
                totalSessionTime: screenTimeToday?.productivityMetrics.totalSessionTime,
                ratio: screenTimeToday?.productivityMetrics.ratio,
            };
            return [date, metrics]
        } else return [date, {}]
    }))
}

export async function pauseScreenTime() {
    return window.api?.call('pauseScreenTime')
}

let recentBuckets;

export async function queryActivityWatch(daysInPast) {
    if (!get(IsElectron) && get(UserInfo).syncActivityWatchEnabled) return;
    if (typeof window === 'undefined' || !window?.location.href.includes('localhost')) return; // we only want it when we're on localhost tbh

    daysInPast = daysInPast || 0;
    if (!get(IsElectron)) return;
    if (!recentBuckets) {
        let buckets = await window.api?.call('getActivityWatchBuckets');
        if (!(buckets && Object.values(buckets).length)) {
            console.error("No buckets: Download and run ActivityWatch to use this functionality.");
            return
        }

        let windowBuckets = Object.values(buckets).filter((bucket) => bucket.id?.includes("window"))
        let afkBuckets = Object.values(buckets).filter((bucket) => bucket.id?.includes("afk"))
        let webBuckets = Object.values(buckets).filter((bucket) => bucket.id?.includes("web"))
        recentBuckets = {
            window: windowBuckets?.length && windowBuckets.sort((bucketA, bucketB) =>
                dayjs(bucketB.last_updated) - dayjs(bucketA.last_updated)
            )[0],
            afk: afkBuckets?.length && afkBuckets.sort((bucketA, bucketB) =>
                dayjs(bucketB.last_updated) - dayjs(bucketA.last_updated)
            )[0],
            web: webBuckets?.length && webBuckets.sort((bucketA, bucketB) =>
                dayjs(bucketB.last_updated) - dayjs(bucketA.last_updated)
            )[0],
        }
    }
    if (!(recentBuckets && Object.values(recentBuckets).some(bucket => bucket))) {
        console.error("No recent buckets: Download and run ActivityWatch to use this functionality.");
        return
    }


    let categories = [...(screenTimeCategories || [])].map(category => {
        let regexObject = {
            "type": "regex",
            "regex": category[1],
            "ignore_case": true
        };
        return [category?.[0], regexObject]
    })

    let body = {
        query: [
            `\n    events = flood(query_bucket("${recentBuckets.window?.id}"));`,
            `\n\nnot_afk = flood(query_bucket("${recentBuckets.afk?.id}"));`,
            '\n         not_afk = filter_keyvals(not_afk, "status", ["not-afk"]);',
            ...(recentBuckets.web ? [`\n  events_chrome = flood(query_bucket("${recentBuckets.web.id}"));`,
                '\n\n    browser_events = [];',
                '\n       window_chrome = filter_keyvals(events, "app", ["Google Chrome","Google-chrome","chrome.exe","google-chrome-stable","Chromium","Chromium-browser","Chromium-browser-chromium","chromium.exe","Google-chrome-beta","Google-chrome-unstable","Brave-browser"]);',
                '\n       events_chrome = filter_period_intersect(events_chrome, window_chrome);',
                '\n       events_chrome = split_url_events(events_chrome);',
                '\n       browser_events = concat(browser_events, events_chrome);',
                '\n       browser_events = sort_by_timestamp(browser_events);',
                'audible_events = filter_keyvals(browser_events, "audible", [true]);',
                '\n             not_afk = period_union(not_afk, audible_events);'] : []),
            '\n events = filter_period_intersect(events, not_afk);',
            ...(recentBuckets.web ? ['\n events_chrome = filter_period_intersect(events_chrome, not_afk);'] : []),
            `\n events = categorize(events, ${JSON.stringify(categories)});`,
            '\n\n    title_events = sort_by_duration(merge_events_by_keys(events, ["app", "title"]));',
            '\n    app_events   = sort_by_duration(merge_events_by_keys(title_events, ["app"]));',
            '\n    cat_events   = sort_by_duration(merge_events_by_keys(events, ["$category"]));',
            '\n\n    app_events  = limit_events(app_events, 100);',
            '\n    title_events  = limit_events(title_events, 100);',
            '\n    duration = sum_durations(events);',
            ...(recentBuckets.web ? ['\n    \n    browser_events = split_url_events(browser_events);',
                '\n    browser_urls = merge_events_by_keys(browser_events, ["url"]);',
                '\n    browser_urls = sort_by_duration(browser_urls);',
                '\n    browser_urls = limit_events(browser_urls, 100);',
                '\n    browser_domains = merge_events_by_keys(browser_events, ["$domain"]);',
                '\n    browser_domains = sort_by_duration(browser_domains);',
                '\n    browser_domains = limit_events(browser_domains, 100);',
                '\n    browser_duration = sum_durations(events_chrome);',] : []),
            `\n\n    RETURN = {\n       "events": events,\n "cat_events": cat_events,\n  "active_events": not_afk,\n            "duration": duration,\n   ${recentBuckets.web ? `"browser_events": events_chrome,\n "duration": browser_duration\n ` : ''}\n    };`
        ],
        timeperiods: [
            `${dayjs().subtract(4, "hour").subtract(daysInPast, "day").startOf('day').hour(4).startOf('hour').format()}/${dayjs().subtract(4, "hour").subtract(daysInPast, "day")
                .endOf('day')
                .add(4, 'hour')
                .format()}`
        ]
    };
    let res = await window.api?.call('getActivityWatchToday', JSON.stringify(body));
    if (!(res && res?.[0])) {
        ;
        return
    }
    let activityWatchToday = res?.[0]?.window?.duration && res?.[0] || undefined;
    activityWatchToday.window = Object.fromEntries(Object.entries(activityWatchToday.window).map(([key, value]) => {
        if (key === "duration") return [key, value];
        else return [key, value && value?.length && value.map(event => {
            let data = { ...(event.data || []) };
            delete event.data;
            return { ...(event || []), ...(data || []) }
        })]
    }))
    activityWatchToday.browser = Object.fromEntries(Object.entries(activityWatchToday.browser).map(([key, value]) => {
        if (key === "duration") return [key, value];
        else return [key, value && value?.length && value.map(event => {
            let data = { ...(event.data || []) };
            delete event.data;
            return { ...(event || []), ...(data || []) }
        })]
    }))

    // let data = get(Data)
    // let day = {}
    // day[dayjs().subtract(4, "hour").subtract(daysInPast, "day").startOf('day').hour(4).startOf('hour').format('YYYY-MM-DD')] = activityWatchToday
    // data[`["timeseries","time_series/activitywatch","noCache"]`] = { ...(data[`["timeseries","time_series/activitywatch","noCache"]`] || {}), ...(day || []) };
    // storeLocal(`["timeseries","time_series/activitywatch","noCache"]`, data[`["timeseries","time_series/activitywatch","noCache"]`])
    // Data.set(data);
    return activityWatchToday;
}



let stepsCalled = false;
export async function getAppleHealthData(sampleName, fileName) {
    let error;
    return;
    let dataStore = get(Data)
    let healthData = checkLocal(JSON.stringify([fileName, "single/apple", "noCache"]));
    if (JSON.stringify(dataStore[JSON.stringify([fileName, "single/apple", "noCache"])]).length === JSON.stringify(healthData).length) return
    dataStore[JSON.stringify([fileName, "single/apple", "noCache"])] = healthData;
    console.log("updating applehealth cache gah")

    Data.set(dataStore);


    let isNative = get(IsNative);
    if (stepsCalled && isNative) await sleep(2000);
    stepsCalled = true;
    let promises = get(Promises);
    let existingPromise = promises[sampleName + fileName + "getappleh"];
    if (existingPromise) {
        await existingPromise.catch(e => error = e);
        if (!error) {
            return existingPromise;
        }
    }
    ;
    let promise;
    if (isNative) {
        await getHKAuth();
        if (!get(UserInfo).syncHKEnabled)
            return;
        let options = {
            sampleName,
            startDate: dayjs(0).format(),
            endDate: dayjs().format(),
            limit: 0,
        };
        ;
        if (sampleName === "stepCount")
            promise = existingPromise || CapacitorHealthkit.queryHKitStatisticsCollection(
                options
            ).then((res) => res?.resultData).catch(e => console.error(e, "queryHKitStatisticsCollection", "getAppleHealthData", options))
        else promise = existingPromise || CapacitorHealthkit.queryHKitSampleType(options).then(
            (res) => res?.resultData
        ).catch(e => console.error(e, "queryHKitStatisticsqueryHKitSampleTypeCollection", "getAppleHealthData", options));
        promises[sampleName + fileName + "getappleh"] = promise
        Promises.set(promises)
        promise.then(healthData => {
            uploadNoPipeline(fileName, "apple", healthData);
            let dataStore = get(Data)
            dataStore[JSON.stringify([fileName, "single/apple", "noCache"])] = healthData;
            storeLocal(JSON.stringify([fileName, "single/apple", "noCache"]), healthData);
            console.log("updating apple from promise gah")

            Data.set(dataStore);
        })
    } else
        promise = await getData(fileName, "apple", "noCache").catch((e) => { console.error(e, "no data for " + fileName); error = e }
        );


    if (error && healthData || !(await promise)) {
        ;
        return healthData;
    }
    // dataStore[JSON.stringify([fileName, "single/apple", "noCache"])] = await promise;
    // storeLocal(JSON.stringify([fileName, "single/apple", "noCache"]), await promise);
    // console.log("updating apple from end of gah")

    // Data.set(dataStore);
    return await promise;
}

export function aggregateByDay(date, individualDay, state, fallback) {
    // console.log("aggregateByDay")
    ;
    if (!individualDay?.length) return {}
    let aggregatedSleepForTheDay = {
        ...(individualDay?.[0] || {}),
        date,
        state,
        endDate: individualDay.map(sleepPeriod => sleepPeriod?.endDate).sort((a, b) => dayjs(b) - dayjs(a))[0],
        duration: individualDay?.[0]
            ? (
                Math.round(
                    individualDay
                        .filter(sleepPeriod => !state || sleepPeriod?.sleepState.toLowerCase() === state.toLowerCase())
                        .map((p) => Number(p?.duration || p.value))
                        .reduce((a, b) => a + b, 0) * 100
                ) / 100)
            : 0
    };
    if (aggregatedSleepForTheDay?.duration > 11.5) {
        // there is an issue when maybe two phones are tracking inbed where it adds both of them
        // should be resolved by checking for overlapping readings and filtering for the longest non-overlapping ones.
        // sort by length, filter whether the start date or end date are within a previous ones start date or end date

        let sortedByDuration = individualDay.sort((a, b) => b?.duration - a?.duration);
        let filteredForOverlap = sortedByDuration.filter((period, i) => i === sortedByDuration?.findIndex(p => {
            let periodStart = dayjs(period.startDate), periodEnd = dayjs(period.endDate), pStart = dayjs(p.startDate), pEnd = dayjs(p.endDate);
            let startDateIsWithin = periodStart >= pStart && periodStart <= pEnd;
            let endDateIsWithin = periodEnd >= pStart && periodEnd <= pEnd;
            return startDateIsWithin || endDateIsWithin
        }))

        if (fallback) aggregatedSleepForTheDay.duration = Math.round(
            filteredForOverlap
                .filter(sleepPeriod => !state || sleepPeriod?.sleepState.toLowerCase() === state.toLowerCase())
                .map((p) => Number(p?.duration || p.value))
                .reduce((a, b) => a + b, 0) * 100
        ) / 100

    }
    return aggregatedSleepForTheDay;
}

let avgInbedAsleepRatio;
export async function formatSleepDump(sleepPeriods, dateRange, sleepState, fallback) {
    if (
        !(sleepPeriods && typeof sleepPeriods === "object" && sleepPeriods?.length)
    ) {
        return [];
    }
    sleepPeriods = sleepPeriods.filter(p => typeof p === "object" && p?.duration !== undefined && (!dateRange || dayjs(p.endDate) > dateRange.start))
    let dates;
    if (dateRange) dates = dateRange.daysInRange
    let sleepPeriodsByDay = {};

    let numTimeZone = (timeZone) => Number(((timeZone || "00:00").replace('Z', '')).split(':')[0]);
    let timeZoneAdjustment = (currentTimeZone, periodTimeZone) => periodTimeZone - currentTimeZone
    sleepPeriods.forEach(p => {
        if (!p.timeZone) console.log({ noTimezone: p })

        let timeAdjustedForTimezone = dayjs(p.endDate || p.date)
            .add(6, "hour")
            .add(timeZoneAdjustment(numTimeZone(dayjs().format().slice(19)), numTimeZone(p.timeZone)), "hour")
        let date = timeAdjustedForTimezone
            .format("YYYY-MM-DD");
        // p.timeAdjustedForTimezone = timeAdjustedForTimezone.format();
        // p.timeNotAdjusted = dayjs(p.endDate || p.date)
        //     .add(6, "hour").format()
        // if (dateRange && date.includes("03-"))

        sleepPeriodsByDay[date] = [...(sleepPeriodsByDay[date] || []), p]
    });

    sleepPeriodsByDay = Object.fromEntries(Object.entries(sleepPeriodsByDay).map(([date, sleepPeriodsToday]) => {
        function whichSleepSource(sleepData) {
            // console.log("whichSleepSource")
            let sleepSources = Array.from(new Set(sleepPeriodsToday.map((s) => s.sourceBundleId)));
            // we know some sources are more accurate than others, and should return them if they exist.
            let prioritySourceOrder = ["oura", "whoop"]
            let prioritySource = prioritySourceOrder.find(source => sleepSources.some(s => s.includes(source)));

            if (prioritySource) return sleepSources.find(s => s.includes(prioritySource))
            // Check for the most complete non-apple source in the data provided, else return apple
            let sleepBySource = sleepSources.map((source) => [
                source,
                sleepData.filter((s) => s.sourceBundleId === source)
            ]);
            let sourcesWithSleepState = sleepBySource.filter(([source, data]) => data.some(period => period.sleepState.toLowerCase() === sleepState.toLowerCase()));
            if (sourcesWithSleepState?.length) sleepBySource = sourcesWithSleepState;
            let sortedSources = sleepBySource
                .sort(([sourceA, periodsA], [sourceB, periodsB]) => periodsB?.length - periodsA?.length)
                .map((s) => s?.[0]);

            let bestSource = sortedSources.filter((s) => !s.includes('com.apple'))[0] || sortedSources?.[0];
            return bestSource;
        };
        let bestSource = whichSleepSource(sleepPeriodsToday);
        ;
        let sleepFromBestSource = sleepPeriodsToday.filter((s) => s.sourceBundleId === bestSource);
        let sleepStatePeriods = sleepFromBestSource.filter((p) => p?.sleepState.toLowerCase() === sleepState.toLowerCase());
        if (fallback && !sleepStatePeriods?.length && sleepPeriodsToday?.length) {

            sleepStatePeriods = sleepPeriodsToday.filter((p, i) => sleepPeriodsToday?.findIndex(pe => pe.uuid === p.uuid) === i).map(period => ({ ...(period || []), duration: period?.duration * 0.9 }));
            // sleepState = sleepState === "inbed" ? "asleep" : "inbed";
            return [date, aggregateByDay(date, sleepStatePeriods || [], undefined, fallback)]
        }
        sleepStatePeriods = sleepStatePeriods.filter((p, i) => sleepStatePeriods?.findIndex(pe => pe.uuid === p.uuid) === i);

        return [date, aggregateByDay(date, sleepStatePeriods || [])]
    }))
    if (!dateRange) dates = Object.keys(sleepPeriodsByDay)
    let aggregatedByDay = await Promise.all(dates.map(async (date, i) => {
        return sleepPeriodsByDay[date]
    }
    ));

    return aggregatedByDay //.filter(a => a);
};

let sleepNeed;
export function calculateSleepNeed(sleepByDay) {

    // if (sleepNeed) return sleepNeed;
    if (!(sleepByDay && typeof sleepByDay === "object" && sleepByDay?.length > 27)) {
        let asleep = sleepByDay && sleepByDay?.[0] && sleepByDay?.[0].state?.toLowerCase() === "asleep"
        let avgSleep = sleepByDay.reduce((a, b) => a + b?.duration, 0) / sleepByDay?.length || (asleep ? 7.6666 : 8.5);

        return (avgSleep + (asleep ? 7.6666 : 8.5)) / 2;
    }
    // find 4 week period with highest average sleep
    // take average of 3rd best week
    // this protects against outliers while finding an amount they can consistently sleep
    let sorted28DaySleepAverages = sleepByDay.map((s, i) =>
        i < 27 ? false :
            {
                date: s.date,
                avg: sleepByDay.slice(i - 27, i + 1)
                    .reduce((a, b) => a + b?.duration, 0) / 28,
                avgs: [0, 1, 2, 3].map(w => sleepByDay.slice(i - 27 + 7 * w, i - 20 + 7 * w)
                    .reduce((a, b) => a + b?.duration, 0) / 7)
            }
    ).filter(a => a && typeof a === "object")
        .sort((a, b) => b.avg - a.avg)
    let thirdBest7DayPeriod = sorted28DaySleepAverages?.[0].avgs.sort()[1];
    sleepNeed = thirdBest7DayPeriod;
    // console.log({ sleepNeed, sleepByDay, sorted28DaySleepAverages })
    return thirdBest7DayPeriod;
}

export function calculateSleepDebt(sleep, sleepNeed) {
    let dateRange = get(DateRange);
    if (!(sleep && typeof sleep === "object" && sleep?.length && sleepNeed && dateRange && typeof dateRange === "object")) {
        return;
    }
    let recentSleep = sleep.filter(sleep => dayjs(sleep.endDate) < dateRange.end && dayjs(sleep.endDate) > dateRange.end?.subtract(14, "day"));
    if (!(recentSleep && recentSleep?.length)) {
        ;
        return
    }
    let dates = Array(14).fill().map((_, i) => dateRange.end?.subtract(14 - i, "day"));
    let averageDuration = recentSleep.map(s => s?.duration).reduce((a, b) => a + b, 0) / recentSleep?.length
    if (recentSleep?.length < 3 && averageDuration !== 0 && averageDuration < 7) averageDuration = (averageDuration + 7.66) / 2
    let sleepWithDebt = dates.map((date, i) => {
        let sleepDay = recentSleep.find(sleep => dayjs(sleep.endDate).date() === date.date()) || {}
        sleepDay.deficit = sleepNeed - (sleepDay && sleepDay?.duration || averageDuration || 7.66);
        sleepDay.debtContributions = sleepDay.deficit * (i + 1) / 14 * Math.exp((i + 1) / 14) / Math.exp(1);
        return sleepDay
    });
    let debt = sleepWithDebt.map(sleepDay => sleepDay.debtContributions).reduce((a, b) => a + b, 0);
    debt < -1.2 ? debt = -1.2 : "";
    return { debt, sleepWithDebt, sleepNeed }
}

export function calculateScores(sleep_debt, wakeup, modifier) {
    if ((!sleep_debt && sleep_debt !== 0) || !wakeup) {
        return;
    };
    modifier = modifier || 1;
    let sleep_debt_modifier = Math.pow(0.969, sleep_debt);
    // let minuteDiff = 30 // wakeup.diff(dayjs().hour(4).startOf('hour').startOf('hour'), 'minute');
    let potentialFunction = (t, sleep_debt, readiness_offset = 0) =>
        Math.round(
            (readiness_offset +
                15
                - sleep_debt * sleep_debt_modifier * 4
                - (1 + sleep_debt * sleep_debt_modifier / 5) * ((t)) / 360 + // sleep_debt lowers floor, floor also declines over time
                15 *
                (5 * Math.exp(-(t) / 2500) + // exponential decay
                    Math.exp(-(t) / 2500) * Math.sin(((t) - 30) / 90) //sin wave
                )
            ) * 1000
        ) / 1000;
    // peak at t=151.3716694115407, as this is where (t - 30) / 90 = PI/2
    let peak = potentialFunction(151.3716694115407 / modifier, sleep_debt, 0)

    let readiness_offset = peak > 99 ? 100 - peak : 0;
    let readiness = peak >= 100 ? 100 : peak;


    // 1440 minutes in a day
    let projected_potential = Array((1440) * modifier).fill().map((a, t) => ({
        timestamp: dayjs(wakeup).add(t / modifier, "minute").format(),
        value: potentialFunction((t) / modifier, sleep_debt, readiness_offset)
    }));

    let scores = {
        readiness,
        sleep_debt,
        projected_potential
    }

    // storeLocal("powerScores", scores)
    return scores;

}

let leaderboardPromises = {};
let lastUpdatedLeaderboard;
let leaderboardUpdating = false;
export async function leaderboardTask(task, groups, username, body, force) {
    // console.log("leaderboardTask")
    if (leaderboardPromises[task]) {
        await leaderboardPromises[task];
    };
    if (!force && (task === 'updateLeaderboard' || task === "leaderboard") && (leaderboardUpdating || lastUpdatedLeaderboard && dayjs().diff(lastUpdatedLeaderboard, "second") < 300)) {
        return
    }
    if (!groups?.length) {
        console.error("no groups", groups, get(UserInfo))
        return;

    }
    leaderboardUpdating = true;
    let url = 'https://leaderboards-queue.magicflow.workers.dev/';
    let tasks = {
        'updateLeaderboard': {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json"
            }
        },
        leaderboard: {
            method: 'GET'
        },
        'deleteLeaderboard': {
            method: 'GET'
        },
        'leaveLeaderboard': {
            method: 'GET'
        }
    };
    if (!tasks[task]) {
        console.error('task not valid', task, groups);
        return;
    };
    let keyValuePairs = objToQueryString({ names: groups.join(','), username, confirm: true });
    let endpoint = `${url}?path=${task}&${keyValuePairs}`;
    leaderboardPromises[task] = fetch(endpoint, tasks[task])
        .then((r) => r.json())
        .catch((e) => {
            console.error(e);
        });
    let result = await leaderboardPromises[task];
    leaderboardPromises[task].then(a => { leaderboardPromises[task] = undefined; leaderboardUpdating = false; })
    // console.log({ result });

    if (!(result && result.leaderboards && result.error === false)) return;
    lastUpdatedLeaderboard = dayjs();
    let {
        error,
        leaderboards
    } = result;
    console.log(result, { leaderboards })
    if (!error && leaderboards?.length) Leaderboards.set(leaderboards)

    return result;
}

export async function categoryTask(task, username, body) {
    let url = 'https://leaderboards-queue.magicflow.workers.dev/';
    let tasks = {
        'updateCategories': {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json"
            }
        },
        categories: {
            method: 'GET'
        }
    };
    if (!tasks[task]) {
        console.error('task not valid', task);
        return;
    };
    if (get(IsElectron)) {
        if (window.ws && task === 'updateCategories') {
            sendViaSocket({ name: task, args: [username, body] });
            window.api?.call(task, username, body);
            return;
        } else
            window.api?.call(task, username, body);
        return;
    }
    let endpoint = `${url}?path=${task}&${username ? `o=${username}` : ''}`;
    let result = await fetch(endpoint, tasks[task])
        .then((r) => r.json())
        .catch((e) => {
            console.error(e);
        });
    console.log({ result });
    if (!result) return;
    let {
        error,
        categories
    } = result;
    // if (!error && categories?.length) screenTime.categorizer.categories = categories;
    return categories;
}

export function mergeEventsCloserThan(gapBetweenEvents, events, mergeLogic) {
    let reversed = [...events.map((e) => ({ ...(e || []) }))].sort(
        (a, b) => dayjs(b.timestamp || b.startDate) - dayjs(a.timestamp || a.startDate)
    ); // going in reverse makes sure multiple mergable sessions are counted correctly
    // if (mergeLogic === "onlyMergeIfSameCategory")
    //     console.log(events, reversed)
    let merged = reversed.map((session, i, observations) => {
        let gapSincePreviousSession = dayjs(
            session.timestamp || session.startedAt || session.startDate || session.start
        ).diff(dayjs(observations[i + 1]?.endDate || observations[i + 1]?.end), 'second');
        let sameCategory = mergeLogic === "onlyMergeIfSameCategory" && observations[i + 1]?.categories?.[0] === session?.categories?.[0];
        let shouldMerge = i < observations?.length - 1 && sameCategory && observations[i + 1]?.endDate && gapSincePreviousSession >= 0 && gapSincePreviousSession < gapBetweenEvents;
        // if (mergeLogic === "onlyMergeIfSameCategory")
        //     console.log({
        //         gapBetweenEvents,
        //         gapSincePreviousSession,
        //         shouldMerge,
        //         session,
        //         obs: observations[i + 1]
        //     });
        if (shouldMerge && i < observations?.length - 1) {
            observations[i + 1].duration = observations[i + 1]?.duration + session.duration;
            observations[i + 1].endDate = session.endDate;
            return false;
        }
        return session;
    });
    return merged?.filter((a) => a);
}
typeof window !== "undefined" ? window.mergeEventsCloserThan = mergeEventsCloserThan : ""

export async function getYoutubeSubtitles(videoId) {



    const RE_YOUTUBE =
        /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;

    class YoutubeTranscriptError extends Error {
        constructor(message) {
            super(`[YoutubeTranscript] 🚨 ${message}`);
        }
    }

    /**
    * Class to retrieve transcript if exist
    */
    class YoutubeTranscript {
        /**
         * Fetch transcript from YTB Video
         * @param videoId Video url or video identifier
         * @param config Get transcript in another country and language ISO
         */
        static async fetchTranscript(
            videoId,
            config
        ) {
            const identifier = this.retrieveVideoId(videoId);
            console.log({ videoId })
            try {
                const videoPageBody = await fetch(
                    `https://cors.magicflow.workers.dev/?https://www.youtube.com/watch?v=${identifier}`
                ).then(t => t.text());
                // console.log(videoPageBody)
                const innerTubeApiKey = videoPageBody
                    .split('"INNERTUBE_API_KEY":"')[1]
                    .split('"')?.[0];
                console.log({ innerTubeApiKey })
                if (innerTubeApiKey && innerTubeApiKey.length > 0) {
                    const res = await fetch(`https://cors.magicflow.workers.dev/?https://www.youtube.com/youtubei/v1/get_transcript?key=${innerTubeApiKey}`, {
                        method: 'POST',
                        body: JSON.stringify(this.generateRequest(videoPageBody, config))
                    });
                    console.log({ res })
                    let body = await res.json()
                    console.log({ youtubeBody: body })
                    if (body.responseContext) {
                        if (!body.actions) {
                            console.error(new Error('Transcript is disabled on this video'));
                        }
                        const transcripts =
                            body?.actions?.[0]?.updateEngagementPanelAction.content
                                .transcriptRenderer.body.transcriptBodyRenderer.cueGroups;
                        if (!transcripts) return []
                        return transcripts.map((cue) => ({
                            text: cue.transcriptCueGroupRenderer.cues[0].transcriptCueRenderer
                                .cue.simpleText,
                            duration: parseInt(
                                cue.transcriptCueGroupRenderer.cues[0].transcriptCueRenderer
                                    .durationMs
                            ),
                            offset: parseInt(
                                cue.transcriptCueGroupRenderer.cues[0].transcriptCueRenderer
                                    .startOffsetMs
                            ),
                        }));
                    }
                }
            } catch (e) {
                console.error(new YoutubeTranscriptError(e));
            }
        }

        /**
         * Generate tracking params for YTB API
         * @param page
         * @param config
         */
        static generateRequest(page, config = {}) {
            const params = page.split('"serializedShareEntity":"')[1] && page.split('"serializedShareEntity":"')[1].split('"')[0];
            const visitorData = page.split('"VISITOR_DATA":"')[1] && page.split('"VISITOR_DATA":"')[1].split('"')[0];
            const sessionId = page.split('"sessionId":"')[1] && page.split('"sessionId":"')[1].split('"')[0];
            const clickTrackingParams1 = page && page.split('"clickTrackingParams":"')[1]
            const clickTrackingParams = clickTrackingParams1 && clickTrackingParams1.split('"')[0];
            return {
                context: {
                    client: {
                        hl: config.lang || 'en',
                        gl: config.country || 'EN',
                        visitorData,
                        userAgent:
                            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/85.0.4183.83 Safari/537.36,gzip(gfe)',
                        clientName: 'WEB',
                        clientVersion: '2.20200925.01.00',
                        osName: 'Macintosh',
                        osVersion: '10_15_4',
                        browserName: 'Chrome',
                        browserVersion: '85.0f.4183.83',
                        screenWidthPoints: 1440,
                        screenHeightPoints: 770,
                        screenPixelDensity: 2,
                        utcOffsetMinutes: 120,
                        userInterfaceTheme: 'USER_INTERFACE_THEME_LIGHT',
                        connectionType: 'CONN_CELLULAR_3G',
                    },
                    request: {
                        sessionId,
                        internalExperimentFlags: [],
                        consistencyTokenJars: [],
                    },
                    user: {},
                    clientScreenNonce: this.generateNonce(),
                    clickTracking: {
                        clickTrackingParams,
                    },
                },
                params,
            };
        }

        /**
         *  'base.js' function
         */
        static generateNonce() {
            const rnd = Math.random().toString();
            const alphabet =
                'ABCDEFGHIJKLMOPQRSTUVWXYZabcdefghjijklmnopqrstuvwxyz0123456789';
            const jda = [
                alphabet + '+/=',
                alphabet + '+/',
                alphabet + '-_=',
                alphabet + '-_.',
                alphabet + '-_',
            ];
            const b = jda[3];
            const a = [];
            for (let i = 0; i < rnd.length - 1; i++) {
                a.push(rnd[i].charCodeAt(i));
            }
            let c = '';
            let d = 0;
            let m, n, q, r, f, g;
            while (d < a.length) {
                f = a[d];
                g = d + 1 < a.length;

                if (g) {
                    m = a[d + 1];
                } else {
                    m = 0;
                }
                n = d + 2 < a.length;
                if (n) {
                    q = a[d + 2];
                } else {
                    q = 0;
                }
                r = f >> 2;
                f = ((f & 3) << 4) | (m >> 4);
                m = ((m & 15) << 2) | (q >> 6);
                q &= 63;
                if (!n) {
                    q = 64;
                    if (!q) {
                        m = 64;
                    }
                }
                c += b[r] + b[f] + b[m] + b[q];
                d += 3;
            }
            return c;
        }

        /**
         * Retrieve video id from url or string
         * @param videoId video url or video id
         */
        static retrieveVideoId(videoId) {
            if (videoId.length === 11) {
                return videoId;
            }
            const matchId = videoId.match(RE_YOUTUBE);
            if (matchId && matchId.length) {
                return matchId[1];
            }
            console.error(new YoutubeTranscriptError(
                'Impossible to retrieve Youtube video ID.'
            ));
        }
    }

    let subtitles = await YoutubeTranscript.fetchTranscript(videoId);
    return subtitles?.map(s => `${s.text}`).join('\n')

}