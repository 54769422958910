import {
    averageOverRange,
    getTimeSeries,
    sleep,
    aggregateAndSort,
    storeLocal
} from '$lib/utils.js';
import {
    aggregateByDay,
    query,
    getAppleHealthData,
    formatSleepDump,
    getGoogleCalendarData,
} from '$lib/connectUtils.js';
import { LiveConnections, DateRange, IsNative, IsElectron, Loading, Data, TodaysData, UserInfo } from "$lib/store.js"
import { get } from 'svelte/store';
import dayjs from 'dayjs';


export function inDateRange(date, dateRange, customDaysFromEnd) {
    return dayjs(date) < dateRange.end && dayjs(date) > (customDaysFromEnd ? dateRange.end?.subtract(customDaysFromEnd, "day") : dateRange.start)
}
export function getTodaysData(todays, appleTimeSeries, whoop, todaysDate, userInfo) {
    let steps = getTodayData('stepCount', todays, whoop, todaysDate, userInfo) || [];

    let sleeps = getTodayData('sleepAnalysis', todays, whoop, todaysDate, userInfo);
    let dateRange = get(DateRange);
    let appleDays = Object.values(appleTimeSeries || {}).filter(a => typeof a === "object")
    return {
        incrementalSteps: steps,
        steps:
            { value: steps.reduce((a, b) => a + b.value, 0) },
        heart_rate: {
            value: averageOverRange(
                (getTodayData('restingHeartRate', todays, whoop, todaysDate, userInfo) || []).map((reading) => reading.value)
            ),
            average: averageOverRange(
                whoop
                    ? Object.values(whoop || {})
                        .filter((a) => a && a?.[0] && a?.[0].recovery && inDateRange(a?.[0].recovery.timestamp, dateRange, 14))
                        .map((day) => day?.[0].recovery.restingHeartRate)
                    : appleDays
                        .map(
                            (day) =>
                                day.restingHeartRate &&
                                day.restingHeartRate?.resultData &&
                                day.restingHeartRate?.resultData.map((reading) => reading.value)
                        )
                        .flat()
            ),
            data: whoop
                ? Object.values(whoop || {})
                    .filter((a) => a && a?.[0] && a?.[0].recovery && inDateRange(a?.[0].recovery.timestamp, dateRange, 14))
                    .sort((a, b) => dayjs(a?.[0].days?.[0]) - dayjs(b?.[0].days?.[0]))
                    .map((whoopDay) => whoopDay?.[0].recovery.restingHeartRate)
                : appleDays
                    .map(
                        (day) =>
                            day.restingHeartRate &&
                            day.restingHeartRate?.resultData &&
                            day.restingHeartRate?.resultData.map((reading) => reading.value)
                    )
                    .flat()
        },
        hrv24hr: {
            value: whoop
                ? whoop[todaysDate] && whoop[todaysDate]?.[0]?.recovery?.heartRateVariabilityRmssd * 1000
                : averageOverRange(
                    (getTodayData('heartRateVariabilitySDNN', todays, whoop, todaysDate, userInfo) || []).map((reading) => reading.value)
                ),
            average: averageOverRange(
                whoop
                    ? Object.values(whoop || {})
                        .filter((a) => a && a?.[0] && a?.[0].recovery && inDateRange(a?.[0].recovery.timestamp, dateRange, 14))
                        .map((day) => day?.[0].recovery.heartRateVariabilityRmssd * 1000)
                    : appleDays
                        .map(
                            (day) =>
                                day.heartRateVariabilitySDNN &&
                                day.heartRateVariabilitySDNN?.resultData &&
                                day.heartRateVariabilitySDNN?.resultData.map((reading) => reading.value)
                        )
                        .flat()
            ),
            data: whoop
                ? Object.values(whoop || {})
                    .filter((a) => a && a?.[0] && a?.[0].recovery && inDateRange(a?.[0].recovery.timestamp, dateRange, 14))
                    .sort((a, b) => dayjs(a?.[0].days?.[0]) - dayjs(b?.[0].days?.[0]))
                    .map((whoopDay) => whoopDay?.[0].recovery.heartRateVariabilityRmssd * 1000)
                : appleDays
                    .map(
                        (day) =>
                            day.heartRateVariabilitySDNN &&
                            day.heartRateVariabilitySDNN?.resultData &&
                            day.heartRateVariabilitySDNN?.resultData.map((reading) => reading.value)
                    )
                    .flat()
        },
        sleep: {
            value: whoop
                ? whoop[todaysDate] && whoop[todaysDate]?.[0]?.sleep?.qualityDuration / 3600000
                : sleeps && aggregateByDay(todaysDate, sleeps, 'asleep'),
            data: sleeps
        }
    };
}
export function generateSleepFromSteps(appleDays) {
    let dates = Object.keys(appleDays).filter(key => key !== "lastUpdated").sort((a, b) => dayjs(a) - dayjs(b));
    let sleepInferred = dates.map((date, i) => {
        let dateRange = { wakeup: dayjs(date).add(4.5, "hour") }
        let todays = appleDays[date]
        let todaysSteps = todays?.stepCount?.resultData;
        if (!(todaysSteps && todaysSteps?.length)) return;
        //first time after 4am they take a step
        let wakeup = todaysSteps.map(step => dayjs(step.startDate)).filter(start => start > dateRange.wakeup).sort((a, b) => a - b)[0]
        let yesterdaysSteps = i ? appleDays[dates[i - 1]]?.stepCount?.resultData || [] : []
        // last time after 6pm-4am they take a step
        let bothSteps = [...(yesterdaysSteps || []), ...(todaysSteps || [])]
        let bedtime = bothSteps.map(
            step => dayjs(step.endDate)
        )
            .filter(end => end < dateRange.wakeup).sort((a, b) => b - a)[0]
        if (!(wakeup && bedtime)) return;
        let duration = wakeup.diff(bedtime, "hour", true) * 0.9 - 0.6; // the mean difference I observed between other inbed estimates by whoop/apple and this
        if (duration > 12) duration = 8.5;
        if (duration < 3) duration = 6.5;
        return {
            startDate: bedtime,
            endDate: wakeup,
            state: 'inbed',
            duration,
            sourceBundleId: "com.apple.stepcount",
            source: "Apple Steps",
            bothSteps
        }
    }).filter(a => a)
    return sleepInferred;
}

export function getWakeUp(recentSleep) {
    if (!(recentSleep && recentSleep?.length && recentSleep?.[0]?.endDate)) {
        ;
        return dayjs().hour(8);
    }

    let sleepSorted = [...(recentSleep || [])].sort((a, b) => dayjs(b.endDate) - dayjs(a.endDate));
    let latestWakeup = dayjs(sleepSorted?.[0].endDate);
    let wakeupToday = dayjs().subtract(4, "hour").hour(latestWakeup.hour()).minute(latestWakeup.minute())
    return wakeupToday;
}
export function getTodayData(attribute, todays, whoop, todaysDate, userInfo) {
    let todaysData;

    if (!get(LiveConnections)?.whoop?.needsWhoopCredentials && userInfo?.syncWhoopEnabled && whoop && (attribute === 'restingHeartRate' || attribute === 'sleepAnalysis')) {
        if (!whoop[todaysDate]) {
            ;
        } else if (attribute === 'restingHeartRate')
            todaysData = [{ value: whoop[todaysDate]?.[0]?.recovery?.restingHeartRate }];
        else if (attribute === 'sleepAnalysis')
            todaysData = whoop[todaysDate]?.[0]?.sleep?.sleeps
                .map((sleep) => [
                    {
                        duration: sleep.qualityDuration / 3600000,
                        endDate: sleep.during.upper,
                        sleepState: 'asleep',
                        source: 'Whoop',
                        sourceBundleId: 'com.whoop',
                        startDate: sleep.during.lower,
                        timeZone: sleep.timezoneOffset.slice(0, 3) + ':' + sleep.timezoneOffset.slice(3)
                    },
                    {
                        duration: (sleep.qualityDuration + sleep.wakeDuration) / 3600000,
                        endDate: sleep.during.upper,
                        sleepState: 'inbed',
                        source: 'Whoop',
                        sourceBundleId: 'com.whoop',
                        startDate: sleep.during.lower,
                        timeZone: sleep.timezoneOffset.slice(0, 3) + ':' + sleep.timezoneOffset.slice(3)
                    }
                ])
                .flat();
    };
    if (!todaysData) {
        todaysData =
            todays &&
            todays[attribute] &&
            todays[attribute].resultData;
    }
    if (todaysData && attribute === 'stepCount') {
        let stepSources = Array.from(new Set(todaysData.map(step => step.sourceBundleId)));
        if (stepSources?.length > 1) {
            let stepsBySource = stepSources.map(source => todaysData.filter(step => step.sourceBundleId === source));
            let sorted = stepsBySource.sort((a, b) => b?.length - a?.length);
            if (sorted?.[0].length)
                todaysData = sorted?.[0];
        }
        todaysData = todaysData?.filter(reading => dayjs(reading.startDate).hour() >= 4) || []
    }
    return todaysData;
}

export function recentAverageDaysSteps(appleTimeSeries, options = {}) {
    // console.log("recentAverageDaysSteps")
    let { cutAtFinalReading, accumulate } = options
    let appleDays = Object.values(appleTimeSeries || {}).filter(day => typeof day === "object")
    let steps = appleDays?.map(todays =>
        todays &&
        getTodayData('stepCount', todays, undefined, undefined, undefined)).filter(a => a).flat().map(step => ({
            ...step, endDate: dayjs(step.endDate)
        }));
    let timeNow = dayjs();
    let dayIn10Minutes = Array(24 * 6)
        .fill()
        .map((a, i) =>
            [dayjs()
                .subtract(4, 'hour')
                .subtract(options.daysInPast || 0, 'day')
                .startOf('day')
                .add(4, 'hour')
                .add(i * 10, 'minute'), []]
        );

    steps.forEach(step => {
        let index = Math.floor(minutesSinceStartOfDay(step.endDate) / 10);
        dayIn10Minutes[index][1].push(step);
    })

    function minutesSinceStartOfDay(date, offset = 4) { return date?.subtract(offset, 'hour').hour() * 60 + date.minute() }
    // console.log("minutesSinceStartOfDay")
    let averageDaysSteps = dayIn10Minutes.map(([time, stepsInPeriod], i) => {
        if (cutAtFinalReading && (time > timeNow)) return [time.format(), false]
        let totalStepsSoFar = (accumulate ? dayIn10Minutes.slice(0, i + 1).map(s => s[1]).flat() : stepsInPeriod)
            .map(step => step.value).reduce((a, b) => a + b, 0);
        let avgStepsSoFar = totalStepsSoFar / appleDays?.length;
        return [time.format(), [{ value: avgStepsSoFar || 0 }]]
    })

    return Object.fromEntries(averageDaysSteps);
}

export function getWhoopSleep(whoop) {
    // console.log("getWhoopSleep")
    return whoop &&
        Object.values(whoop)
            .filter((a) => a && a?.length)
            .map((whoop) => {
                if (whoop && whoop?.[0])
                    return whoop?.[0].sleep?.sleeps
                        .map((sleep) => [
                            {
                                duration: sleep.qualityDuration / 3600000,
                                endDate: sleep.during.upper,
                                sleepState: 'asleep',
                                source: 'Whoop',
                                sourceBundleId: 'com.whoop.ethi',
                                startDate: sleep.during.lower,
                                timeZone: sleep.timezoneOffset.slice(0, 3) + ':' + sleep.timezoneOffset.slice(3),
                                uuid: sleep.during.upper + sleep.during.lower + (sleep.qualityDuration / 3600000) + 'asleep'
                            },
                            {
                                duration: (sleep.qualityDuration + sleep.wakeDuration) / 3600000,
                                endDate: sleep.during.upper,
                                sleepState: 'inbed',
                                source: 'Whoop',
                                sourceBundleId: 'com.whoop.ethi',
                                startDate: sleep.during.lower,
                                timeZone: sleep.timezoneOffset.slice(0, 3) + ':' + sleep.timezoneOffset.slice(3),
                                uuid: sleep.during.upper + sleep.during.lower + ((sleep.qualityDuration + sleep.wakeDuration) / 3600000) + 'inbed'
                            }
                        ])
                        .flat();
            })
            .flat();
}

export function findPeaks(arr = []) {
    let positions = [];
    let maximas = [];
    let minimas = [];
    let minimaPositions = [];
    for (let i = 2; i < arr?.length - 2; i++) {
        if (arr[i] > arr[i - 1] && arr[i - 1] > arr[i - 2]) {
            if (arr[i] > arr[i + 1] && arr[i + 1] > arr[i + 2]) {
                positions.push(i);
                maximas.push(arr[i]);
            } else if (arr[i] === arr[i + 1]) {
                let temp = i;
                while (arr[i] === arr[temp]) i++;
                if (arr[temp] > arr[i] && arr[i] > arr[i + 1]) {
                    positions.push(temp);
                    maximas.push(arr[temp]);
                }
            }
        }
        if (arr[i] < arr[i - 1] && arr[i - 1] < arr[i - 2]) {
            if (arr[i] < arr[i + 1]) {
                minimaPositions.push(i);
                minimas.push(arr[i]);
            } else if (arr[i] === arr[i + 1]) {
                let temp = i;
                while (arr[i] === arr[temp]) i++;
                if (arr[temp] < arr[i] && arr[i] < arr[i + 1]) {
                    minimaPositions.push(temp);
                    minimas.push(arr[temp]);
                }
            }
        }
    }
    return { maximas, positions, minimas, minimaPositions };
};

export function sortFlatLogs(input) {
    let logs = [...(input || [])].filter((l) => l?.timeStamp);
    if (!(logs && logs?.length && logs.flat().length)) return;
    let sorted = logs.flat().sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
    return sorted;
}
export function accumulateObjects(accumulated, day, i, array) {

    if (!day) { console.log({ day, array }); return accumulated }
    // if (!accumulated?.length) return { ...day, length: array?.length };

    let length = array?.filter(a => a?.productivityScore).length
    Object.entries(day).forEach(([key, value]) => {
        if (typeof value === 'number' && !isNaN(value))
            accumulated[key] = (accumulated[key] || 0) + value / (value <= 1 || key === "sessionLength" ? length : 1);
        else if (typeof value === 'object' && value?.length)
            accumulated[key] = [
                ...(typeof accumulated[key] === 'object' && accumulated[key].length
                    ? accumulated[key]
                    : []),
                ...value
            ];
        else if (value && typeof value === 'object') {
            // merge if value is an object by adding numerical keys together
            Object.entries(value || {}).forEach(([k, v]) => {
                if (typeof v === 'number' && !isNaN(v))
                    accumulated[key] = {
                        ...accumulated[key],
                        [k]: (accumulated[key]?.[k] || 0) + v
                    };
                else if (typeof v === 'object' && v?.length)
                    accumulated[key] = {
                        ...accumulated[key],
                        [k]: [
                            ...(typeof accumulated[key]?.[k] === 'object' && accumulated[key]?.[k].length
                                ? accumulated[key]?.[k]
                                : []),
                            ...v
                        ]
                    };
                else
                    accumulated[key] = {
                        ...accumulated[key],
                        [k]: v
                    };
            })
        }

    });
    accumulated.length = array?.length;
    // console.log({ ...accumulated, i, day });
    return accumulated;
}

export function bucketEventsByTimePeriod(eventsToAggregate, options, since) {
    if (!eventsToAggregate?.length || !options || !options.timePeriod) {
        return [];
    }
    let { timePeriod, quantityOfPeriod, offset, key, splitSpanningEvents } = options;
    // offset = {value, period}
    let eventsByPeriod = {};

    let events = eventsToAggregate && [...eventsToAggregate].map(e => ({ ...e })) //.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp));
    function getPeriod(date) {
        if (quantityOfPeriod) {
            let startOfPeriod = Math.floor(date[timePeriod]() / quantityOfPeriod) * quantityOfPeriod;
            return date[timePeriod](startOfPeriod).startOf(timePeriod);
        } else {
            return date.startOf(timePeriod);
        }
    }
    function makePeriodString(date) {
        return getPeriod(date).format();
    }
    let firstEvent = events?.[0];
    let lastEvent = events?.[events?.length - 1];
    if (!firstEvent || !lastEvent) return [];
    let firstDate = getPeriod(dayjs(key || firstEvent.date || firstEvent.timestamp || firstEvent.endDate).subtract(quantityOfPeriod || 1, timePeriod).add(
        (offset && offset.value) || 0,
        (offset && offset.period) || 'day'
    ))
    let lastDate = getPeriod(dayjs(key || lastEvent.endDate || lastEvent.date).add(quantityOfPeriod || 1, timePeriod).add(
        (offset && offset.value) || 0,
        (offset && offset.period) || 'day'
    ))
    let periodsInRange = Math.ceil(lastDate.diff(firstDate, timePeriod || "hour") / (quantityOfPeriod || 1));
    // for each period In Day, make a period string, and use that as a key for the eventsByPeriod object and give it an empty array as a value
    for (let i = 0; i < periodsInRange + 1; i++) {
        let period = firstDate.add(i * (quantityOfPeriod || 1), timePeriod);
        eventsByPeriod[makePeriodString(period)] = [];
    }
    if (lastDate.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
        eventsByPeriod[makePeriodString(dayjs())] = [];
    }
    // if (options.source === "todaysData") {
    //     console.log({
    //         firstDate: firstDate.format(),
    //         lastDate: lastDate.format(),
    //         firstEvent,
    //         lastEvent,
    //         periodsInRange
    //     })
    //     console.log({ eventsByPeriod, options })
    // }
    let logged = 0;

    events.forEach((event) => {
        let date = dayjs(key || event.date || event.timestamp || event.endDate).add(
            (offset && offset.value) || 0,
            (offset && offset.period) || 'day'
        );


        let period = getPeriod(date);
        let periodString = period.format();

        if (splitSpanningEvents) {
            let endOfEvent = event.endDate ? dayjs(event.endDate) : date.add(event?.duration, 'second');
            let endOfEventString = makePeriodString(endOfEvent);
            let endOfEventTimePeriod = period
                .add(quantityOfPeriod || 1, timePeriod)
                .startOf(timePeriod);
            if (endOfEventString !== periodString) {
                // new event has timestamp of start of period, and duration since start of period.
                // even allows for more than 2 periods spanned
                // start of period is
                let numberOfPeriodsDifferent = Math.floor(
                    endOfEvent.diff(period, timePeriod, true) / (quantityOfPeriod || 1)
                );



                // add newEvents to eventsByPeriod
                Array(typeof numberOfPeriodsDifferent === "number" && !isNaN(numberOfPeriodsDifferent) && numberOfPeriodsDifferent >= 0 ? numberOfPeriodsDifferent : 0)
                    .fill()
                    .forEach((_, periodsDifferent) => {

                        periodsDifferent = periodsDifferent + 1;
                        let newEvent = { ...(event || []) };
                        let startOfNewTimePeriod = period
                            .add(periodsDifferent * (quantityOfPeriod || 1), timePeriod)
                            .startOf(timePeriod);
                        let endOfNewTimePeriod = period
                            .add((periodsDifferent + 1) * (quantityOfPeriod || 1), timePeriod)
                            .startOf(timePeriod);
                        newEvent.timestamp = startOfNewTimePeriod.format();
                        newEvent.duration = (
                            endOfEvent < endOfNewTimePeriod ? endOfEvent : endOfNewTimePeriod
                        ).diff(startOfNewTimePeriod, 'second');
                        let newPeriodString = makePeriodString(startOfNewTimePeriod);
                        eventsByPeriod[newPeriodString] = [
                            ...(eventsByPeriod[newPeriodString] || []),
                            ...(!(since && dayjs(newEvent.timestamp).add(newEvent?.duration, 'second') < since)
                                ? [newEvent]
                                : [])
                        ];
                        if (periodsDifferent === 1) {
                            event.duration = endOfEventTimePeriod.diff(date, 'second', true);
                            event.endDate = startOfNewTimePeriod.format();
                        }// else console.log({ newEvent, event })
                    });

                // if (quantityOfPeriod === 180 && logged < 5 && numberOfPeriodsDifferent > 1 || !(typeof numberOfPeriodsDifferent === "number" && !isNaN(numberOfPeriodsDifferent) && numberOfPeriodsDifferent >= 0)) {
                //     logged++;
                //     console.log({
                //         'numberOfPeriodsDifferent': numberOfPeriodsDifferent,
                //         endOfEvent: endOfEvent.format(),
                //         endOfEventTimePeriod: endOfEventTimePeriod.format(),
                //         endOfEventString,
                //         periodString,
                //         event: event
                //     });
                // }
            }
        }
        eventsByPeriod[periodString] = [
            ...(eventsByPeriod[periodString] || []),
            ...(!since || dayjs(event?.timestamp).add(event?.duration, 'second') > since ? [event] : [])
        ];
    });
    // console.log({ eventsByPeriod, options, })
    return eventsByPeriod;
}
let browsers = [
    'Google Chrome',
    'Safari',
    'Brave',
    'Gener8',
    'sigma',
    'sidekick',
    'firefox',
    'opera',
    'vivaldi',
    'browser',
    'arc'
];
export function timeRangeForActivity(activity) {
    if (!activity) return '';
    // console.log({ activity })
    return dayjs(activity.startDate || activity.timestamp).format('HH:mm') +
        '-' +
        dayjs(activity.endDate || activity.end)
            .format('HH:mm')
}
let lastUpdated = {
    daysInPast: 0,
    updated: undefined
};
let googleCalendarData;
export async function setTodayData(daysInPast, source) {
    let dateRange = get(DateRange);
    // console.log(
    //     'starting today data',
    //     { daysInPast, 'dateRange.daysInPast': dateRange.daysInPast },
    //     // input?.productiveEvents?.length,
    //     source
    // );
    getGoogleCalendarData({
        start: dayjs().subtract(4, 'hour').startOf('day'),
        end: dayjs().subtract(4, 'hour').endOf('day')
    }).then(r => googleCalendarData = r).catch((e) => console.error(e));
    let dataGetters = {
        apple: get(IsNative) ? query('apple') : (daysInPast) => getTimeSeries('apple', { daysInPast }),
        activitywatch: get(IsElectron)
            ? query('screenTime')
            : (daysInPast) => getTimeSeries('activitywatch', { daysInPast }),
        whoop: query('whoop')
    };
    if (get(Loading)) await sleep(200);

    if (daysInPast === lastUpdated.daysInPast && lastUpdated.updated > Date.now() - 1000 * 10)
        return;
    Loading.set(true);
    lastUpdated = {
        daysInPast: daysInPast,
        updated: Date.now()
    };

    daysInPast = daysInPast || 0;
    if (!daysInPast) getAppleHealthData('stepCount', 'steps.json');

    await sleep(50);
    let todaysDayjs = dayjs()
        .subtract(4, 'hour')
        .subtract(dateRange.daysInPast, 'day')
    let todaysDate =
        todaysDayjs.format('YYYY-MM-DD');
    let endOfDay = todaysDayjs.endOf('day').add(4, 'hour');
    let data = get(Data);
    if (!data[`["timeseries","time_series/activitywatch","noCache"]`]?.[todaysDate] || daysInPast && dayjs(data[`["timeseries","time_series/activitywatch","noCache"]`]?.[todaysDate].lastUpdated) < endOfDay) {
        let promises = ['activitywatch', 'apple', 'whoop'].map(async (source, i) => {
            // await sleep(i * 5000);
            // console.log('updating ', source);
            let data = await dataGetters[source](daysInPast, undefined, true);

            return data;
            // .then((a) => console.log(source, ' updated'));
        });
        await sleep(50);

        await promises?.[0];
    }
    data = get(Data)
    let accumulated = getAccumulatedProductivityMetrics(
        data[`["timeseries","time_series/activitywatch","noCache"]`]?.[todaysDate]
    );
    let stepsDataStore = [];
    stepsDataStore = data[`["steps.json","single/apple","noCache"]`] || [];
    let events = (
        data[`["timeseries","time_series/activitywatch","noCache"]`]?.[todaysDate]?.window ||
        data[`["timeseries","time_series/activitywatch","noCache"]`]?.[todaysDate]
    )?.events?.map((e) => ({
        ...e
    }));
    let calendarEvents = (get(LiveConnections).google || []).filter(
        (e) =>
            e.transparency !== 'transparent' &&
            e.attendees &&
            e.attendees?.find((a) => a.self)?.responseStatus === 'accepted' &&
            dayjs(e.start.dateTime) < dayjs() &&
            dayjs(e.start.dateTime) > dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour')
    ).map((e) => {
        // if (events)
        //     events = events.filter(
        //         (ev) =>
        //             !between(ev.timestamp, e.start.dateTime, e.end.dateTime) &&
        //             !between(ev.endDate, e.start.dateTime, e.end.dateTime)
        //     );
        return {
            ...(e || []),
            duration: dayjs(e.end.dateTime).diff(dayjs(e.start.dateTime), 'second'),
            startDate: dayjs(e.start.dateTime).toDate(),
            timestamp: dayjs(e.start.dateTime).toDate(),
            endDate: dayjs(e.end.dateTime).toDate(),
            name: e.summary,
            title: e.summary,
            categories: ['Meetings'],
            app: e.summary,
            work: true,
            focus: false,
            attributes: [e.kind?.replace('#', ' ')]
        };
    });
    console.log({ calendarEvents })
    events = [
        ...(events || []),
        // ...calendarEvents
    ]
    if (data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
        todaysDate
    ]?.window?.events) {
        data[`["timeseries","time_series/activitywatch","noCache"]`][
            todaysDate
        ].window.events = events;
        console.log("updating activitywatch from setTodayData")

        Data.set(data)
    }
    let productiveEvents = [
        ...(events?.filter((event) => event?.work) || [])
    ].sort((a, b) => dayjs(typeof a.timestamp == "string" ? a.timestamp : a.timestamp) - dayjs(typeof b.timestamp == "string" ? b.timestamp : b.timestamp)); // productiveEvents;

    let unproductiveEvents = events?.filter((event) => !event?.work); // unproductiveEvents;
    let sessions = data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
        todaysDate
    ]?.window?.sessions
    let contexts = sessions?.map((s) => s.contexts).flat();
    let deepEvents = contexts?.filter(
        (a, i, ar) => a?.focus && (a?.duration > 300 || !i) && i === ar.findIndex((c) => c?.id === a?.id)
    );
    let shallowEvents = contexts?.filter(
        (a, i) => a?.work && !(a?.focus && (a?.duration > 300 || !i))
    );


    // console.log({ contexts, deepContexts, shallowContexts });
    let total_time = {
        deep: deepEvents?.reduce((a, b) => a + (b?.[1]?.duration || 0), 0),
        shallow: shallowEvents?.map((a) => a[1])?.reduce((a, b) => a + (b?.duration || 0), 0),
        unproductive: (events || [...(unproductiveEvents || [])])
            ?.filter((e) => !e?.work)
            ?.reduce((a, b) => a + (b?.duration || 0), 0),
        productive: events?.filter((e) => e?.work)?.reduce((a, b) => a + (b?.duration || 0), 0),
        total: events?.reduce((a, b) => a + (b?.duration || 0), 0)
    };

    await sleep(100); // just to allow other things to execute
    let input = {
        productiveEvents,
        productiveEventsBucketed:
            productiveEvents &&
            bucketEventsByTimePeriod([...(productiveEvents || [])], {
                timePeriod: 'minute',
                quantityOfPeriod: 30,
                splitSpanningEvents: true,
                source: 'todaysData'
            }),
        unproductiveEvents,
        unproductiveEventsBucketed:
            unproductiveEvents &&
            bucketEventsByTimePeriod([...(unproductiveEvents || [])], {
                timePeriod: 'minute',
                quantityOfPeriod: 30,
                splitSpanningEvents: true,
                source: 'todaysData'
            }),
        calendarEvents,
        proportions: [
            productiveEvents,
            // false, supposed to be for dead time but not working well yet;
            unproductiveEvents
        ].map(
            (catEvents) =>
                aggregateAndSort(catEvents || []).reduce((a, b) => a + (b?.[1]?.duration || 0), 0) /
                (aggregateAndSort(events || []) || []).reduce((a, b) => a + (b?.[1]?.duration || 0), 0.01)
        ),
        eventsByType: {
            deep: deepEvents,
            shallow: shallowEvents,
            unproductive: unproductiveEvents
        },
        total_time,
        accumulated
    };
    console.log(
        'setting today data',
        { daysInPast, 'dateRange.daysInPast': dateRange.daysInPast },
        input?.productiveEvents?.length,
        source
    );
    if (!input?.productiveEvents) {
        console.log('no inputs', 'setTodayData', source);
        return;
    }

    if (googleCalendarData && googleCalendarData.length) {
        let lc = get(LiveConnections)
        lc.google = (
            googleCalendarData ||
            lc.google ||
            (checkLocal('gcal')?.length && checkLocal('gcal')) ||
            []
        ).filter(
            (e) =>
                dayjs(e.start.dateTime) >
                dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour')
        );
        LiveConnections.set(lc)
        storeLocal('gcal', lc.google);
        console.log({ google: lc.google });
    }
    let todaysData = get(TodaysData);
    console.log("updating todaydata")

    TodaysData.set({
        apple: data[`["timeseries","time_series/apple","noCache"]`]?.[todaysDate],
        activitywatch: data[`["timeseries","time_series/activitywatch","noCache"]`]?.[todaysDate],
        whoop: data[`["timeseries","time_series/whoop","noCache"]`]?.[todaysDate],
        average: data[`["timeseries","time_series/activitywatch","noCache"]`]?.[todaysDate]?.productivityByHour?.map(({ start }, i) => {
            return {
                ...Object.values(data[`["timeseries","time_series/activitywatch","noCache"]`] || [])
                    ?.map((d) => d?.productivityByHour?.[i])
                    .filter((a) => a)
                    .reduce(accumulateObjects, {}),
                start
            };
        }),
        stepsBucketed: {
            today: recentAverageDaysSteps(
                [
                    (data[`["timeseries","time_series/apple","noCache"]`] || {})[
                    dayjs()
                        .subtract(4, 'hour')
                        .subtract(dateRange.daysInPast, 'day')
                        .format('YYYY-MM-DD')
                    ]
                ],
                { cutAtFinalReading: true, accumulate: true, daysInPast: dateRange.daysInPast }
            ),
            average: recentAverageDaysSteps(
                data[`["timeseries","time_series/apple","noCache"]`] || {},
                {
                    accumulate: true,
                    daysInPast: dateRange.daysInPast
                }
            )
        },
        steps:
            (todaysData?.steps?.dots?.data?.length && todaysData.steps) ||
            formatSteps(
                typeof stepsDataStore === 'object' ? stepsDataStore : [],
                dateRange,
                dateRange?.daysInRange
            ),
        heartData:
            (todaysData?.heartData?.line?.data?.length && todaysData.heartData) ||
            await formatHeart(
                data[`["timeseries","time_series/apple","noCache"]`],
                data[`["timeseries","time_series/whoop","noCache"]`]
            ),
        sleepData:
            (todaysData?.sleepData?.dots?.data?.length && todaysData.sleepData) ||
            await formatSleep(
                data[`["timeseries","time_series/apple","noCache"]`],
                data[`["timeseries","time_series/whoop","noCache"]`],
                data[`["sleep.json","single/apple","noCache"]`]
            ),
        ...(input || {}),
        ...lastUpdated,
        lastUpdated:
            data?.[`["timeseries","time_series/activitywatch","noCache"]`]?.[
                dayjs().subtract(4, 'hour').format('YYYY-MM-DD')
            ]?.lastUpdated
    })
    console.log('set finished');

    Loading.set(false);
    postSet();
}
async function postSet() {
    // let data = get(Data)
    // await sleep(5000);
    // storeLocal(
    //     `["timeseries","time_series/activitywatch","noCache"]`,
    //     data[`["timeseries","time_series/activitywatch","noCache"]`]
    // );
    // await sleep(3000);
    // storeLocal(
    //     `["timeseries","time_series/apple","noCache"]`,
    //     data[`["timeseries","time_series/apple","noCache"]`]
    // );
}
typeof window !== "undefined" ? window.setTodayData = setTodayData : "";
async function formatSleep(appleData, whoopData, sleep) {
    let whoopSleep;
    if (get(UserInfo).syncWhoopEnabled) {
        whoopSleep =
            whoopData &&
            Object.values(whoopData)
                .map((whoop) => {
                    if (whoop)
                        return whoop?.[0]?.sleep?.sleeps
                            .map((sleep) => [
                                {
                                    duration: sleep.qualityDuration / 3600000,
                                    endDate: sleep.during.upper,
                                    sleepState: 'asleep',
                                    source: 'Whoop',
                                    sourceBundleId: 'com.whoop',
                                    startDate: sleep.during.lower,
                                    timeZone: sleep.timezoneOffset.slice(0, 3) + ':' + sleep.timezoneOffset.slice(3)
                                },
                                {
                                    duration: (sleep.qualityDuration + sleep.wakeDuration) / 3600000,
                                    endDate: sleep.during.upper,
                                    sleepState: 'inbed',
                                    source: 'Whoop',
                                    sourceBundleId: 'com.whoop',
                                    startDate: sleep.during.lower,
                                    timeZone: sleep.timezoneOffset.slice(0, 3) + ':' + sleep.timezoneOffset.slice(3)
                                }
                            ])
                            .flat();
                })
                .flat();
    }
    if (!(appleData && Object.values(appleData).filter((a) => typeof a === 'object').length)) {
        if (whoopSleep) return makeSleepData(whoopSleep);
        else return makeSleepData([[], []]);
    }
    let appleDays = Object.values(appleData).filter((a) => typeof a === 'object');
    let allSleep = appleDays
        .map((appleDay) => appleDay?.sleepAnalysis?.resultData)
        .flat()
        .filter((a) => a)
        .flat();

    allSleep = [
        ...(allSleep || []),
        ...(whoopSleep || []),
        ...((allSleep &&
            allSleep.length &&
            allSleep) ||
            [])
    ];
    let dateRange = get(DateRange);

    let aggregatedDaySleep = await Promise.all(
        ['inbed', 'asleep'].map((state) => formatSleepDump(sleep, dateRange, state))
    );

    function makeSleepData(aggregatedDaySleep) {
        let [inbed, asleep] = aggregatedDaySleep;
        let dateRange = get(DateRange);

        return {
            dots: {
                name: 'inbed',
                data: inbed.map((p) => p?.duration || p)
            },
            bar: {
                name: 'asleep',
                data: asleep.map((p) => p?.duration || p)
            },
            axis: dateRange.daysInRange.map((day) => day.slice(5))
        };
    }

    let sleepData = makeSleepData(aggregatedDaySleep);
    // console.log({ sleep, aggregatedDaySleep, sleepData, appleData });
    return sleepData;
}
function formatSteps(steps, dateRange, daysInRange) {
    steps = steps || [];
    if (typeof steps !== 'object' || !steps?.length) {
        return {
            dots: {
                name: '',
                data: []
            },
            bar: {
                name: 'Steps',
                data: daysInRange.map((d) => 0)
            },
            axis: daysInRange.map((d) => dayjs(d).format('MM-DD'))
        };
    }

    let indexOfEarliestStepsInRange = steps?.findIndex(
        (step) => dayjs(step.startDate).format('YYYY-MM-DD') === daysInRange?.[0]
    );
    let indexOfLatestStepsInRange = dateRange.daysInPast
        ? steps?.findIndex(
            (step) =>
                dayjs(step.startDate).format('YYYY-MM-DD') === daysInRange[daysInRange?.length - 1]
        )
        : steps?.length;
    if (indexOfEarliestStepsInRange === -1) indexOfEarliestStepsInRange = 0;
    if (indexOfLatestStepsInRange === -1) indexOfLatestStepsInRange = steps?.length;
    let stepsInRange = steps.slice(indexOfEarliestStepsInRange, indexOfLatestStepsInRange + 1);
    let inrange = daysInRange.map(
        (date) =>
            stepsInRange.find((step) => dayjs(step.startDate).format('YYYY-MM-DD') === date) || {
                startDate: date,
                value: 0
            }
    );
    let stepsData = {
        dots: {
            name: '',
            data: []
        },
        bar: {
            name: 'Steps',
            data: inrange.map((step) => step.value)
        },
        axis: inrange.map((step) => dayjs(step.startDate).format('MM-DD'))
    };

    return stepsData;
}
async function formatHeart(appleData, whoopData) {
    let dateRange = get(DateRange);

    let heartData = {
        line: {
            name: 'Resting Heart Rate',
            data: []
        },
        axis: dateRange.daysInRange.map((day) => day.slice(5))
    };
    if (!appleData && !whoopData) return;
    let heartDays = dateRange.daysInRange?.map((date) =>
        getTodayData(
            'restingHeartRate',
            // oscarsHealth,
            appleData[date],
            whoopData,
            date,
            get(UserInfo)
        )
    );

    heartData.line.data = heartDays?.map((todaysHeart) =>
        averageOverRange(todaysHeart?.length && todaysHeart?.map((h) => h.value))
    );
    return heartData;
}

function aggregateEvents(events) {
    // console.log('aggregateEvents');
    return (
        events &&
        events?.length &&
        (events.map((e) => e?.duration).reduce((a, b) => a + b, 0) < 0
            ? 0
            : events && events?.length && events?.map((e) => e?.duration).reduce((a, b) => a + b, 0))
    );
}


export function between(date, start, end) {
    let d = dayjs(date);
    return d >= dayjs(start) && d <= dayjs(end);
}

function getAccumulatedProductivityMetrics(todayScreentime) {
    let todayProductivityByHour = (todayScreentime?.productivityByHour || []).map((hour, i) => [
        dayjs(typeof hour.start == "string" ? hour.start : hour.start).format(),
        dayjs(typeof hour.start == "string" ? hour.start : hour.start) > dayjs() ? false : hour?.productivityScore || 0
    ]);
    let data = get(Data)
    let allProductivityByHour = Object.values(
        data[`["timeseries","time_series/activitywatch","noCache"]`] || {}
    ).filter(screenTimeDay => (screenTimeDay?.productivityMetrics?.totalSessionTime > 60 * 30) && dayjs(screenTimeDay?.start).format('YYYY-MM-DD') !== dayjs(todayScreentime?.start).format('YYYY-MM-DD')).map((screenTimeDay) => screenTimeDay?.productivityByHour || []);
    let avgProductivityByHour = todayProductivityByHour.map(([hour, _], i, array) => [
        hour,
        allProductivityByHour
            .map((day) => day[i]?.productivityScore || 0)
            .reduce((a, b) => a + b, 0) /
        (allProductivityByHour.filter((day) => day[i]?.productivityScore || 0).length || 1)
    ]);
    let todayTractionByHour = [...(todayScreentime?.productivityByHour || [])].map(
        (hour) =>
            (hour?.contexts || [])
                ?.map((c) => {
                    return (
                        c?.duration *
                        (
                            (todayScreentime?.window?.sessions || []).find(
                                (s) =>
                                    dayjs(typeof s.timestamp == "string" ? s.timestamp : s.timestamp) < dayjs(typeof c.timestamp == "string" ? c.timestamp : c.timestamp) && dayjs(s.endDate) > dayjs(c.endDate)
                            ) || hour
                        )?.productivityScore
                    );
                })
                .reduce((a, b) => a + b, 0) || 0
    );

    let todayTractionAccumulated = todayProductivityByHour.map(([hour, _], i, array) => [
        hour,
        dayjs(hour).subtract(0.99, 'hour') > dayjs()
            ? false
            : todayTractionByHour.slice(0, i + 1).reduce((a, b) => a + b, 0)
    ]);
    let avgTractionAccumulated = todayProductivityByHour.map(([hour, _], i, array) => [
        hour,
        allProductivityByHour
            ?.map((day) =>
                day
                    .slice(0, i + 1)
                    .map((hour) => hour?.productivityScore * hour.totalSessionTime || 0)
                    .reduce((a, b) => a + b, 0)
            )
            .reduce((a, b) => a + b, 0) / allProductivityByHour?.length
    ]);
    let todayDeepWorkAccumulated = [...(todayScreentime?.productivityByHour || [])].map(
        (hour, i, array) => [
            todayProductivityByHour[i][0],
            dayjs(hour.start).subtract(0.99, 'hour') > dayjs()
                ? false
                : array.slice(0, i + 1).reduce((a, b) => a + (b?.deepWork || 0), 0)
        ]
    );
    let avgDeepWorkAccumulated = todayProductivityByHour.map(([hour, _], i, array) => [
        hour,
        allProductivityByHour
            ?.map((day) =>
                day
                    .slice(0, i + 1)
                    .map((hour) => hour?.deepWork || 0)
                    .reduce((a, b) => a + b, 0)
            )
            .reduce((a, b) => a + b, 0) / allProductivityByHour?.length
    ]);
    let todayDurationAccumulated = [...(todayScreentime?.productivityByHour || [])].map(
        (hour, i, array) => [
            todayProductivityByHour[i][0],
            dayjs(hour.start).subtract(0.99, 'hour') > dayjs()
                ? false
                : array.slice(0, i + 1).reduce((a, b) => a + (b?.totalSessionTime || 0), 0)
        ]
    );
    let avgDurationAccumulated = todayProductivityByHour.map(([hour, _], i, array) => [
        hour,
        allProductivityByHour
            ?.map((day) =>
                day
                    .slice(0, i + 1)
                    .map((hour) => hour?.totalSessionTime > 0 ? hour?.totalSessionTime || 0 : 0)
                    .reduce((a, b) => a + b, 0)
            )
            .reduce((a, b) => a + b, 0) / allProductivityByHour?.length
    ]);

    return {
        productivityScore: { today: todayProductivityByHour, average: avgProductivityByHour },
        traction: { today: todayTractionAccumulated, average: avgTractionAccumulated },
        totalSessionTime: { today: todayDurationAccumulated, average: avgDurationAccumulated },
        'deep work': { today: todayDeepWorkAccumulated, average: avgDeepWorkAccumulated }
    };
}